import {
  PmDashboardChartBase,
  PmDashboardChartBaseBaseProps,
} from './PmDashboardChartBase';

export const AverageDailyRateCard = ({
  endDate,
  startDate,
  currency,
}: PmDashboardChartBaseBaseProps) => {
  return (
    <PmDashboardChartBase
      title="Average Daily Rate"
      href="/reservations"
      total="average"
      isCurrencyAmount
      type="averageDailyRate"
      startDate={startDate}
      endDate={endDate}
      currency={currency}
    />
  );
};
