import { AlertTriangleIcon, CheckCircleIcon } from '@finalytic/icons';
import { LoadingIndicator } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { Stack, Text, useMantineTheme } from '@mantine/core';

export const SyncedTablePlaceholder = ({
  status,
  message,
}: { status: Maybe<string>; message?: string }) => {
  const { colors } = useMantineTheme();

  const text: Record<string, string> = {
    completed: 'No errors, all parts are running smoothly.',
    failed: 'Something went wrong, please try rerunning.',
    started: 'Syncing data...',
  };

  return (
    <Stack mih={200} align="center" justify="center">
      {status === 'started' ? (
        <LoadingIndicator size="sm" />
      ) : status === 'completed' ? (
        <CheckCircleIcon color={colors.green[5]} size={30} />
      ) : (
        <AlertTriangleIcon color={colors.red[5]} size={30} />
      )}
      <Text color="neutral">
        {text[status || 'started']}
        {message ? ` - ${message}` : ''}
      </Text>
    </Stack>
  );
};
