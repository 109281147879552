import { Button, Collapse, IconButton, Timeline } from '@finalytic/components';
import { useMutation, useTeam } from '@finalytic/data';
import type { party_enum } from '@finalytic/graphql';
import {
  CalendarDatesIcon,
  CalendarEventIcon,
  CreditCardIncomeIcon,
  CrossCircleIcon,
  Edit3Icon,
  HomeIcon,
  Icon,
  LoaderIcon,
  UserIcon,
} from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuItem,
  StringParam,
  useQueryParamSet,
} from '@finalytic/ui';
import {
  type Maybe,
  formatCurrency,
  hasValue,
  sum,
  toTitleCase,
  uniqueBy,
  utc,
} from '@finalytic/utils';
import {
  Anchor,
  Avatar,
  Box,
  Card,
  Center,
  Group,
  HoverCard,
  LoadingOverlay,
  Progress,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { ComponentPropsWithoutRef, forwardRef, useMemo, useState } from 'react';
import { ReservationStatusBadge } from '../../components';
import { ReservationPaymentStatusBadge } from '../../components/ReservationPaymentStatusBagde';
import { useJournalEntriesModal } from '../../modals';
import { DepositLineAmountHoverCard } from '../../views/reconciliation/_components';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { useDepositDetailDrawer } from '../deposit-drawer';
import { ReservationEditForm } from './ReservationEditForm';
import { ReservationLineDeleteModal } from './ReservationLineDeleteModal';
import { ReservationLineFormModal } from './ReservationLineFormModal';
import {
  type Reservation,
  useLedgerReservationDetailDrawerQuery,
} from './useLedgerReservationDetailDrawerQuery';
import { useReservationDetailDrawer } from './useReservationDetailDrawer';

export const LedgerReservationDetailDrawer = () => {
  const { opened, close, reservationId, view, setView } =
    useReservationDetailDrawer();
  const { open: openJournalEntriesModal } = useJournalEntriesModal();

  const { colorScheme } = useMantineColorScheme();

  const { colors } = useMantineTheme();
  const [{ id: teamId }] = useTeam();
  const { isLoading, data: reservation } =
    useLedgerReservationDetailDrawerQuery(reservationId);

  const updatePaymentData = useMutation(
    (
      q,
      { reservationId, userdata }: { reservationId: string; userdata: any }
    ) => {
      return q.updateReservation({
        pk_columns: {
          id: reservationId,
        },
        _set: {
          userdata,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['reservations'],
    }
  );

  const hasUserDataEditAccess = [
    '5599d7c0-a692-423c-9e06-3fac8d63048d',
  ].includes(teamId);

  return (
    <Drawer opened={opened} onClose={close} size={550}>
      <DrawerHeader
        closeDrawer={close}
        title={
          reservation && (
            <Group mt={rem(5)} wrap="nowrap">
              <Avatar src={reservation.connection.app.iconRound} />
              <Box>
                <Text size="lg" fw={500}>
                  {reservation.guestName}
                </Text>
                <Text
                  size="xs"
                  c={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
                  fw={400}
                >
                  {`${uniqueBy(
                    [
                      reservation?.confirmationCode,
                      reservation?.pmsReferenceCode,
                    ].filter((x) => x)
                  ).join(', ')}`}
                </Text>
              </Box>
            </Group>
          )
        }
        type="Reservation"
        loading={isLoading}
        menuItems={
          view === 'overview' &&
          reservation && (
            <>
              {hasUserDataEditAccess && (
                <EllipsisMenuItem
                  onClick={() => setView('edit')}
                  customIcon={<Edit3Icon size={16} />}
                >
                  Edit User Data
                </EllipsisMenuItem>
              )}
              <EllipsisMenuItem
                onClick={() =>
                  openJournalEntriesModal({
                    reservationId: { _eq: reservation.id },
                  })
                }
                customIcon={<Icon icon="ListUnorderedIcon" size={16} />}
              >
                Journal Entries
              </EllipsisMenuItem>
            </>
          )
        }
      />
      {!reservation && !isLoading ? (
        'No reservation found'
      ) : view === 'edit' && reservation ? (
        <ReservationEditForm
          initialValues={{
            userData: JSON.stringify(reservation.userData || {}, null, 2),
          }}
          onReset={() => setView('overview')}
          isLoading={isLoading}
          handleSubmit={async (values) => {
            updatePaymentData
              .mutate({
                args: {
                  reservationId: reservation.id,
                  userdata: JSON.parse(values.userData?.trim() || '{}'),
                },
              })
              .then(() => setView('overview'));
          }}
        />
      ) : (
        <Content reservation={reservation} isLoading={isLoading} />
      )}
    </Drawer>
  );
};

const Content = ({
  reservation,
  isLoading,
}: { reservation: Maybe<Reservation>; isLoading: boolean }) => {
  const setListing = useQueryParamSet('listing', StringParam);
  const setView = useQueryParamSet('view', StringParam);
  const userDataKeys = Object.keys(reservation?.userData || {});

  if (!reservation) return null; // payments/resolutions grouped

  const paymentsCentTotal = sum(reservation.payments, 'centTotal');

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
        flex: 1,
      }}
    >
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: <ReservationStatusBadge status={reservation.status} />,
          },
          {
            icon: HomeIcon,
            title: 'Listing',
            text: (
              <Anchor
                onClick={() => setListing(reservation.listing.id, 'push')}
              >
                {reservation.listing.name}
              </Anchor>
            ),
          },
          {
            icon: CalendarEventIcon,
            title: 'Booked',
            text: `${
              reservation.bookedAt
                ? utc(reservation.bookedAt).format('MMM DD, YYYY')
                : 'unknown date'
            }, ${toTitleCase(reservation.channel || 'unknown channel')}`,
          },
          {
            icon: CrossCircleIcon,
            title: 'Cancelled At',
            text:
              reservation.status === 'cancelled' && reservation.cancelledAt
                ? utc(reservation.cancelledAt).format('MMM DD, YYYY')
                : undefined,
          },
          {
            icon: CalendarDatesIcon,
            title: 'Dates',
            text: [reservation.checkIn, reservation.checkOut]
              .filter(hasValue)
              .map((x) => utc(x).format('MMM DD, YYYY'))
              .join(' - '),
          },
          {
            icon: UserIcon,
            title: 'User data',
            text: !!userDataKeys.length && (
              <Anchor onClick={() => setView('edit')}>
                {userDataKeys.length} updated key
                {userDataKeys.length > 1 && 's'}
              </Anchor>
            ),
          },

          {
            icon: CreditCardIncomeIcon,
            title: 'Balance',
            text: (
              <ReservationPaymentStatusBadge
                paidStatus={reservation.paidStatus}
                paymentsCentTotal={paymentsCentTotal}
                currency={reservation.currency}
              />
            ),
          },
        ]}
      />

      {/* Statistics */}
      <Statistics {...reservation} />

      <Financials
        title="Financials"
        included={reservation.financials.included}
        exluded={reservation.financials.exluded}
        reservationId={reservation.id}
      />

      <RecurringFees
        title="Fees and Commissions"
        rowData={reservation.recurringFees}
        currency={reservation.currency}
      />

      {/* Payments */}
      <Payments
        rowData={reservation.payments}
        title="Payments"
        currency={reservation.currency}
        deposits={reservation.deposits}
      />

      {/* Refunds */}
      {!!reservation.refunds.length && (
        <Payments
          rowData={reservation.refunds}
          title="Refunds"
          currency={reservation.currency}
          deposits={reservation.deposits}
        />
      )}

      {/* Reserves */}
      {!!reservation.reserves.length && (
        <Payments
          rowData={reservation.reserves}
          title="Reserves"
          currency={reservation.currency}
          deposits={reservation.deposits}
        />
      )}

      {/* Resolutions */}
      {!!reservation.resolutions.length && (
        <Payments
          rowData={reservation.resolutions}
          title="Resolutions"
          currency={reservation.currency}
          deposits={reservation.deposits}
        />
      )}

      <ReservationTimeline data={reservation.timeline} />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

const Statistics = (reservation: Reservation) => {
  const theme = useMantineTheme();

  // TOTALS
  const currency = reservation.currency;
  const total = sum(reservation.financials.included, 'centTotal');
  const pmRevenue = reservation.recurringFees.reduce(
    (acc, curr) => acc + curr.centTotal,
    0
  );
  // TODO: @LarsBehrenberg GL - how to get taxes?
  const taxes = reservation.financials.included.reduce(
    (acc, curr) =>
      acc + (curr.description?.includes('tax') ? curr.centTotal : 0),
    0
  );
  const ownerRevenue = total - pmRevenue - taxes;

  // COLORS
  const ownerRevenueColor = theme.colors.blue[5];
  const pmRevenueColor = theme.colors.violet[5];
  const taxesColor = theme.colors.orange[5];
  const borderColor = theme.colors.neutral[2];

  const formatted = {
    pmRevenue: formatCurrency(pmRevenue / 100, currency),
    ownerRevenue: formatCurrency(ownerRevenue / 100, currency),
    taxes: formatCurrency(taxes / 100, currency),
    reservation: formatCurrency(total / 100, currency),
  };

  return (
    <SimpleGrid
      cols={{ lg: 4 }}
      spacing={'1px'}
      sx={{
        backgroundColor: borderColor,
        border: '1px solid',
        borderColor,
        borderRadius: theme.radius.md,
        overflow: 'hidden',
        ' > div': {
          paddingInline: theme.spacing.xs,
          paddingTop: theme.spacing.sm,
          paddingBottom: theme.spacing.xs,
        },
      }}
    >
      <Stat label="Reservation">{formatted.reservation}</Stat>
      <Stat label="PM revenue" color={pmRevenueColor}>
        {formatted.pmRevenue}
      </Stat>
      <Stat label="Taxes" color={taxesColor}>
        {formatted.taxes}
      </Stat>

      <HoverCard withArrow shadow="md" position="bottom-end" arrowOffset={55}>
        <HoverCard.Target>
          <Stat label="Owner revenue" color={ownerRevenueColor}>
            {formatted.ownerRevenue}
          </Stat>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <Stack gap="xs">
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                Reservation
              </Text>
              <Text ta="right" fw={500}>
                {formatted.reservation}
              </Text>
            </Group>
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                PM revenue
              </Text>
              <Text ta="right" fw={500}>
                -{formatted.pmRevenue}
              </Text>
            </Group>
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                Taxes
              </Text>
              <Text ta="right" fw={500}>
                -{formatted.taxes}
              </Text>
            </Group>
          </Stack>
        </HoverCard.Dropdown>
      </HoverCard>

      <Card
        radius={0}
        sx={{
          gridColumn: 'span 4',
        }}
      >
        <Progress.Root size="sm">
          <Tooltip label="PM revenue" withArrow>
            <Progress.Section
              value={(pmRevenue / total) * 100}
              color="violet"
            />
          </Tooltip>
          <Tooltip label="Taxes" withArrow>
            <Progress.Section value={(taxes / total) * 100} color="orange" />
          </Tooltip>
          <Tooltip label="Owner revenue" withArrow>
            <Progress.Section
              value={(ownerRevenue / total) * 100}
              color="blue"
            />
          </Tooltip>
        </Progress.Root>
      </Card>
    </SimpleGrid>
  );
};

const Stat = forwardRef<
  HTMLDivElement,
  {
    color?: string;
    label: string;
    children: string;
  } & ComponentPropsWithoutRef<'div'>
>(({ children, label, color, ...props }, ref) => {
  const theme = useMantineTheme();

  return (
    <Card radius={0} ref={ref} {...props}>
      <Group mb={rem(3)} gap={rem(4)} wrap="nowrap" align="flex-start">
        {color && (
          <Box
            mt={1.5}
            sx={{
              height: rem(8),
              borderRadius: '10px',
              width: '2px',
              flexShrink: 0,
              backgroundColor: color,
            }}
          />
        )}
        <Text c={theme.colors.gray[6]} size={rem(12)}>
          {label}
        </Text>
      </Group>
      <Text fw={500}>{children}</Text>
    </Card>
  );
});

const Financials = ({
  included,
  exluded,
  title,
  reservationId,
}: {
  included: Reservation['financials']['included'];
  exluded: Reservation['financials']['exluded'];
  title: string;
  reservationId: string;
}) => {
  const theme = useMantineTheme();

  const [opened, setOpened] = useState<{
    reservationId: string;
    lineType: string | null;
    description: string;
    centTotal: number | undefined;
    party: party_enum;
  } | null>(null);

  const [deleteModalReservationLine, setDeleteModalReservationLine] = useState<{
    id: string;
    reservationId: string;
  } | null>(null);

  const total = sum(included, (x) => x.centTotal || 0) / 100;

  const currency = included[0]?.currency;

  const columns = useMemo<
    MRT_ColumnDef<Reservation['financials']['included'][0]>[]
  >(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        mantineTableFooterCellProps: {
          pl: 'xs',
        },
        mantineTableBodyCellProps: {
          px: 'xs',
        },
        Footer: () => (
          <Text span fw={500}>
            Total guest charged
          </Text>
        ),
        Cell: ({ row }) => {
          const description = row.original.description;

          const isManual = !row.original.connectionId;

          if (isManual) {
            return (
              <Group gap={5} wrap="nowrap">
                <Text>{description}</Text>
                <IconButton
                  icon="TrashIcon"
                  size={16}
                  tooltip="Delete adjustment"
                  onClick={() =>
                    setDeleteModalReservationLine({
                      reservationId,
                      id: row.original.id,
                    })
                  }
                />
              </Group>
            );
          }

          return description;
        },
      },
      {
        header: 'amount',
        accessorKey: 'amount',
        maxSize: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.centTotal || 0) / 100,
            row.original.currency
          );

          return amount;
        },
        Footer: () => (
          <Text span fw={500} ta="right" display={'block'}>
            {formatCurrency(total, currency)}
          </Text>
        ),
      },
    ],
    [currency, total, reservationId]
  );

  return (
    <Box>
      <ReservationLineFormModal
        closeModal={() => setOpened(null)}
        reservationId={opened?.reservationId || null}
        reservationLine={opened}
      />
      <ReservationLineDeleteModal
        closeModal={() => setDeleteModalReservationLine(null)}
        reservationLine={deleteModalReservationLine}
      />
      <DrawerCollapsableTable
        title={title}
        customRowCount={
          !exluded.length ? undefined : (
            <>
              {included.length}{' '}
              <HoverCard withArrow shadow="md">
                <HoverCard.Target>
                  <Text span size="xs">
                    ({exluded.length})
                  </Text>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Stack gap="xs">
                    <Text fw={500} flex={1}>
                      Exluded financials
                    </Text>
                    {exluded.map((line) => (
                      <Group key={line.id} wrap="nowrap" align="flex-start">
                        <Text c="gray" flex={1}>
                          {line.description}
                        </Text>
                        <Text ta="right" fw={500}>
                          {formatCurrency(
                            (line.centTotal ?? 0) / 100,
                            currency
                          )}
                        </Text>
                      </Group>
                    ))}
                  </Stack>
                </HoverCard.Dropdown>
              </HoverCard>
            </>
          )
        }
        rightSection={
          <Button
            variant="light"
            size="xs"
            leftIcon="PlusIcon"
            color="neutral"
            iconColor={theme.colors.neutral[5]}
            onClick={() =>
              setOpened({
                centTotal: undefined,
                description: '',
                lineType: null,
                reservationId,
                party: 'manager',
              })
            }
          >
            Add adjustment
          </Button>
        }
        rowData={included}
        columns={columns}
        emptyRowsFallback={() => (
          <Center>
            <Text size="sm" c="gray">
              No financials available
            </Text>
          </Center>
        )}
      />
    </Box>
  );
};

const Payments = ({
  rowData,
  deposits,
  currency,
  title,
}: {
  rowData: Reservation['payments'] | Reservation['resolutions'];
  deposits: Reservation['deposits'];
  title: string;
  currency: string;
}) => {
  const { open: openDeposit } = useDepositDetailDrawer();

  const total =
    rowData.reduce((acc, line) => {
      return (
        acc +
        line.centTotal -
        line.merchantFeeCentTotal -
        ('channelFeeCentTotal' in line ? line.channelFeeCentTotal : 0)
      );
    }, 0) / 100;

  const columns = useMemo<
    MRT_ColumnDef<Reservation['payments'][0] | Reservation['resolutions'][0]>[]
  >(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const theme = useMantineTheme();

          const deposit = deposits.find(
            (x) => x.id === row.original.transactionId
          );
          return (
            <Box>
              <Text>
                {row.original.description ||
                  (row.original.type === 'payment' ? 'Payment' : 'Resolution')}
              </Text>
              {deposit && (
                <Anchor
                  size="xs"
                  c={theme.primaryColor}
                  component="button"
                  td="none"
                >
                  Deposit{' '}
                  {formatCurrency(deposit.centTotal / 100, deposit.currency)}
                  {deposit.date && ` on ${utc(deposit.date).format('LL')}`}
                </Anchor>
              )}
            </Box>
          );
        },
      },
      {
        header: 'Badge',
        maxSize: 0,
        minSize: 120,
        size: 120,
        Cell: ({ row }) => {
          const connection = deposits.find(
            (x) => x.id === row.original.transactionId
          )?.connection;

          return (
            <Group
              wrap="nowrap"
              gap={5}
              sx={(theme) => ({
                backgroundColor: theme.colors.gray[1],
                border: `1px solid ${theme.colors.gray[2]}`,
                paddingInline: rem(6),
                paddingBlock: rem(3),
                borderRadius: theme.radius.xl,
              })}
            >
              {connection?.app.iconRound ? (
                <Avatar
                  size={16}
                  radius="xl"
                  mr={rem(3)}
                  src={connection.app.iconRound}
                />
              ) : (
                <Icon icon="PlusCircleIcon" size={14} />
              )}
              <Text span size="xs">
                {connection?.app.name || 'Manual'}
              </Text>
            </Group>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 0,
        minSize: 90,
        size: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return (
            <DepositLineAmountHoverCard {...row.original} currency={currency} />
          );
        },
      },
    ],
    [currency, deposits]
  );

  return (
    <DrawerCollapsableTable
      title={title}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={rowData}
      columns={columns}
      onRowClick={{
        handler: (row) => {
          if (row.original.transactionId)
            openDeposit(row.original.transactionId, 'push');
        },
        disabled: (row) => !row.original.transactionId,
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No payments found
          </Text>
        </Center>
      )}
    />
  );
};

const RecurringFees = ({
  rowData,
  currency,
  title,
}: {
  rowData: Reservation['recurringFees'];
  title: string;
  currency: string;
}) => {
  const total = sum(rowData, (x) => x.centTotal) / 100;

  const columns = useMemo<MRT_ColumnDef<Reservation['recurringFees'][0]>[]>(
    () => [
      {
        header: 'Title',
        accessorKey: 'title',
        Footer: () => (
          <Text span fw={500}>
            Total to PM
          </Text>
        ),
        mantineTableFooterCellProps: {
          pl: 'xs',
        },
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 0,
        minSize: 90,
        size: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
        },
        Cell: ({ row }) => {
          return formatCurrency(
            row.original.centTotal / 100,
            row.original.currency
          );
        },
        Footer: () => {
          return (
            <Text span fw={500} ta="right" display={'block'}>
              {formatCurrency(total, currency)}
            </Text>
          );
        },
      },
    ],
    [currency, total]
  );

  return (
    <DrawerCollapsableTable
      title={title}
      rightSection={null}
      rowData={rowData}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No fees and commissions found
          </Text>
        </Center>
      )}
    />
  );
};

const ReservationTimeline = ({ data }: { data: Reservation['timeline'] }) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  if (!data.length) return null;

  return (
    <Box>
      <Collapse
        title={
          <Text component="span" size="sm">
            Timeline
            <Text
              c={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
              ml="xs"
              size="xs"
              component="span"
            >
              {data.length}
            </Text>
          </Text>
        }
        minHeight={30}
        defaultOpened={!!data.length}
      >
        <Timeline data={data} order="desc" />
      </Collapse>
    </Box>
  );
};
