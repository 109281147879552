import {
  PmDashboardChartBase,
  PmDashboardChartBaseBaseProps,
} from './PmDashboardChartBase';

export const ActiveListingsCard = ({
  endDate,
  startDate,
  currency,
}: PmDashboardChartBaseBaseProps) => {
  return (
    <PmDashboardChartBase
      title="Active Listings Created"
      href="/listings"
      total="sum"
      type="activeListingsCreated"
      startDate={startDate}
      endDate={endDate}
      currency={currency}
    />
  );
};
