import {
  SpotlightContext,
  spotlightContext,
  useDashboard,
  useMe,
  useMutation,
  useSpotlightContext,
  useTeam,
  useTeamRole,
} from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { useV2UI } from '@finalytic/ui';
import { useClipboard, useLocalStorage } from '@mantine/hooks';
import { hideNotification, showNotification } from '@mantine/notifications';
import {
  Spotlight,
  SpotlightActionData as SpotlightAction,
} from '@mantine/spotlight';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';

import '@mantine/spotlight/styles.css';

type Ctx = {
  spotlight: SpotlightContext;
  navigate: NavigateFunction;

  toggleV2Ui: () => void;
  isV2Ui: boolean;

  toggleIsDarkTheme: () => void;
  isDarkTheme: boolean;

  copyTeamId: () => void;
  teamId: string;

  toggleTeamStatus?: () => void;
};

type ActionFn = (ctx: Ctx) => SpotlightAction[];

const baseActions: ActionFn = ({ navigate }: Ctx) => [
  {
    group: 'Navigate',
    label: 'Home',
    id: 'home',
    description: 'Get to home page',
    onClick: () => navigate('/'),
    leftSection: <Icon icon="HomeIcon" size={18} />,
  },
];
const partnerAdminActions: ActionFn = ({ copyTeamId, teamId }: Ctx) => {
  const actions: SpotlightAction[] = [];

  actions.push({
    label: 'Copy Team ID',
    group: 'Partner Actions',
    description: `${teamId}`,
    id: 'copy-team-id',
    onClick: copyTeamId,
    leftSection: <Icon icon="Tag2Icon" size={18} />,
  });
  return actions;
};

const superAdminActions: ActionFn = ({
  spotlight,
  toggleV2Ui,
  isV2Ui,
  isDarkTheme,
  toggleIsDarkTheme,
  toggleTeamStatus,
}: Ctx) => {
  const actions: SpotlightAction[] = [];

  actions.push({
    group: 'Super Admin Actions',
    label: isDarkTheme ? 'Disable Dark Theme' : 'Enable Dark Theme',
    description: 'Toggle Dark Theme',
    onClick: toggleIsDarkTheme,
    id: 'dark-theme',
    leftSection: <Icon icon="ToggleOnIcon" size={18} />,
  });

  actions.push({
    group: 'Super Admin Actions',
    label: isV2Ui ? 'Disable UI feature testing' : 'Enable UI feature testing',
    description: 'Toggle all UI features in development',
    onClick: toggleV2Ui,
    id: 'v2-ui',
    leftSection: <Icon icon="ToggleOnIcon" size={18} />,
  });

  if (toggleTeamStatus) {
    actions.push({
      group: 'Super Admin Actions',
      label: 'Toggle Team Status',
      description: 'Toggle Team Status (Active <=> Onboarding)',
      onClick: toggleTeamStatus,
      id: 'toggle-team-status',
      leftSection: <Icon icon="ToggleOnIcon" size={18} />,
    });
  }

  actions.push({
    label: 'Hypervisor Queue',
    group: 'Queue',
    description: spotlight.hypervisorQueue
      ? 'Reset hypervisor queue'
      : 'Set hypervisor queue',
    id: 'hypervisor-queue',
    onClick: () => {
      const input = prompt('Enter hypervisor queue');
      spotlight.set({ ...spotlight, hypervisorQueue: input || undefined });
    },
    leftSection: <Icon icon="Tag2Icon" size={18} />,
  });

  return actions;
};

export function SpotlightComponentProvider({
  children,
}: {
  children: ReactNode;
}) {
  const spotlight = useSpotlightContext();
  const navigate = useNavigate();
  const user = useMe();
  const { copy } = useClipboard();
  const {
    toggleV2: toggleV2Ui,
    isV2,
    isDarkTheme,
    toggleIsDarkTheme,
  } = useV2UI();

  const [{ id: teamId, createdAt: teamCreatedAt, isOnboarding }, refetchTeam] =
    useTeam();
  const { isPartnerAdmin, isSuperAdmin } = useTeamRole();
  const [dashboard] = useDashboard();

  const copyTeamId = () => copy(teamId);

  const { mutate } = useMutation(
    (q, args: { tenantId: string; isOnboarding: boolean }) => {
      return q.updateTenantById({
        pk_columns: {
          id: args.tenantId,
        },
        _set: {
          isOnboarding: !args.isOnboarding,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['teams'],
      successMessage: {
        message: 'Successfully toggled team status',
      },
    }
  );

  const actions = useMemo(() => {
    const ctx: Ctx = {
      spotlight,
      navigate,
      teamId,
      copyTeamId,
      toggleV2Ui,
      isV2Ui: isV2,
      isDarkTheme,
      toggleIsDarkTheme,
      toggleTeamStatus:
        dashboard !== 'propertyManager'
          ? undefined
          : () =>
              mutate({
                args: { tenantId: teamId, isOnboarding },
              }).then(refetchTeam),
    };
    if (isSuperAdmin) {
      return [
        ...baseActions(ctx),
        ...partnerAdminActions(ctx),
        ...superAdminActions(ctx),
      ];
    }
    if (isPartnerAdmin) {
      return [...baseActions(ctx), ...partnerAdminActions(ctx)];
    }
    return baseActions(ctx);
  }, [
    user?.id,
    spotlight,
    toggleV2Ui,
    teamId,
    isV2,
    isOnboarding,
    dashboard,
    teamCreatedAt,
  ]);

  return (
    <>
      {children}

      <Spotlight
        actions={actions}
        searchProps={{
          leftSection: <Icon icon="SearchIcon" size={18} />,
          placeholder: 'Search...',
        }}
        shortcut={['mod + P', 'mod + K', '/']}
        nothingFound="Nothing found..."
        radius="md"
        closeOnActionTrigger
      />
    </>
  );
}

export function SpotlightConfigProvider({ children }: { children: ReactNode }) {
  const ref = useRef<SpotlightContext>({
    set(_state: SpotlightContext) {
      if (_state.hypervisorQueue) s(_state.hypervisorQueue);
      else r();
    },
  } as any);

  const [hypervisorQueue, s, r] = useLocalStorage({ key: 'hypervisorQueue' });
  ref.current.hypervisorQueue = hypervisorQueue;

  useEffect(() => {
    hideNotification('hypervisor-queue');
    if (ref.current.hypervisorQueue)
      showNotification({
        id: 'hypervisor-queue',
        onClick: (e) => {
          // did not click on close svg
          if ((e.target as any)?.nodeName !== 'svg') return;

          ref.current.set((state) => ({
            ...state,
            hypervisorQueue: undefined,
          }));
        },
        autoClose: false,
        title: 'Hypervisor Queue',
        message: ref.current.hypervisorQueue,
        color: 'orange',
      });
  }, [ref.current.hypervisorQueue]);

  return (
    <spotlightContext.Provider value={ref.current}>
      {children}
    </spotlightContext.Provider>
  );
}
