// generated with @7nohe/openapi-react-query-codegen@1.6.1 

import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AccountsService, BankAccountsService, BankRecordsService, ConnectionsService, ContactsService, ListingsOwnershipPeriodsService, ListingsService, MetricsService, PaymentsService, RatesService, RecurringFeesListingPeriodsService, RecurringFeesService, ReservationsService, StatementsLayoutsService, StatementsManagerService, StatementsService, TeamsService, TransactionsService } from "../requests/services.gen";
import * as Common from "./common";
export const usePaymentsServiceGetPayment = <TData = Common.PaymentsServiceGetPaymentDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTeamId }: {
  id: string;
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UsePaymentsServiceGetPaymentKeyFn({ id, xTeamId }, queryKey), queryFn: () => PaymentsService.getPayment({ id, xTeamId }) as TData, ...options });
export const usePaymentsServiceGetPayments = <TData = Common.PaymentsServiceGetPaymentsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, limit, page, status, xTeamId }: {
  date?: string;
  limit?: number;
  page?: number;
  status?: "arrived" | "cancelled" | "pending";
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UsePaymentsServiceGetPaymentsKeyFn({ date, limit, page, status, xTeamId }, queryKey), queryFn: () => PaymentsService.getPayments({ date, limit, page, status, xTeamId }) as TData, ...options });
export const usePaymentsServiceGetPaymentsByCurrency = <TData = Common.PaymentsServiceGetPaymentsByCurrencyDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, xTeamId }: {
  date?: string;
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UsePaymentsServiceGetPaymentsByCurrencyKeyFn({ date, xTeamId }, queryKey), queryFn: () => PaymentsService.getPaymentsByCurrency({ date, xTeamId }) as TData, ...options });
export const useReservationsServiceGetReservation = <TData = Common.ReservationsServiceGetReservationDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTeamId }: {
  id: string;
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseReservationsServiceGetReservationKeyFn({ id, xTeamId }, queryKey), queryFn: () => ReservationsService.getReservation({ id, xTeamId }) as TData, ...options });
export const useReservationsServiceGetReservations = <TData = Common.ReservationsServiceGetReservationsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, dateField, id, limit, listingId, page, status, xTeamId }: {
  date?: string;
  dateField?: "bookedAt" | "checkIn" | "checkOut" | "payment" | "intersection";
  id?: string;
  limit?: number;
  listingId?: string;
  page?: number;
  status?: "cancelled" | "booked" | "inquiry";
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseReservationsServiceGetReservationsKeyFn({ date, dateField, id, limit, listingId, page, status, xTeamId }, queryKey), queryFn: () => ReservationsService.getReservations({ date, dateField, id, limit, listingId, page, status, xTeamId }) as TData, ...options });
export const useListingsOwnershipPeriodsServiceGetListingOwnerships = <TData = Common.ListingsOwnershipPeriodsServiceGetListingOwnershipsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ listingId, xTeamId }: {
  listingId?: string;
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListingsOwnershipPeriodsServiceGetListingOwnershipsKeyFn({ listingId, xTeamId }, queryKey), queryFn: () => ListingsOwnershipPeriodsService.getListingOwnerships({ listingId, xTeamId }) as TData, ...options });
export const useListingsOwnershipPeriodsServiceGetListingOwnership = <TData = Common.ListingsOwnershipPeriodsServiceGetListingOwnershipDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTeamId }: {
  id: string;
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListingsOwnershipPeriodsServiceGetListingOwnershipKeyFn({ id, xTeamId }, queryKey), queryFn: () => ListingsOwnershipPeriodsService.getListingOwnership({ id, xTeamId }) as TData, ...options });
export const useListingsServiceGetListing = <TData = Common.ListingsServiceGetListingDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTeamId }: {
  id: string;
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListingsServiceGetListingKeyFn({ id, xTeamId }, queryKey), queryFn: () => ListingsService.getListing({ id, xTeamId }) as TData, ...options });
export const useListingsServiceGetListings = <TData = Common.ListingsServiceGetListingsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, status, xTeamId }: {
  limit?: number;
  page?: number;
  status?: "active" | "inactive";
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListingsServiceGetListingsKeyFn({ limit, page, status, xTeamId }, queryKey), queryFn: () => ListingsService.getListings({ limit, page, status, xTeamId }) as TData, ...options });
export const useTeamsServiceGetTeam = <TData = Common.TeamsServiceGetTeamDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTeamsServiceGetTeamKeyFn({ id }, queryKey), queryFn: () => TeamsService.getTeam({ id }) as TData, ...options });
export const useTeamsServiceGetTeams = <TData = Common.TeamsServiceGetTeamsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, limit, page, status }: {
  date?: string;
  limit?: number;
  page?: number;
  status?: "active" | "inactive" | "deleted" | "onboarding";
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTeamsServiceGetTeamsKeyFn({ date, limit, page, status }, queryKey), queryFn: () => TeamsService.getTeams({ date, limit, page, status }) as TData, ...options });
export const useRecurringFeesListingPeriodsServiceGetRecurringFeeListingPeriods = <TData = Common.RecurringFeesListingPeriodsServiceGetRecurringFeeListingPeriodsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, recurringFeeId, xTeamId }: {
  limit?: number;
  page?: number;
  recurringFeeId?: string;
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRecurringFeesListingPeriodsServiceGetRecurringFeeListingPeriodsKeyFn({ limit, page, recurringFeeId, xTeamId }, queryKey), queryFn: () => RecurringFeesListingPeriodsService.getRecurringFeeListingPeriods({ limit, page, recurringFeeId, xTeamId }) as TData, ...options });
export const useRecurringFeesServiceGetRecurringFee = <TData = Common.RecurringFeesServiceGetRecurringFeeDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTeamId }: {
  id: string;
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRecurringFeesServiceGetRecurringFeeKeyFn({ id, xTeamId }, queryKey), queryFn: () => RecurringFeesService.getRecurringFee({ id, xTeamId }) as TData, ...options });
export const useRecurringFeesServiceGetRecurringFees = <TData = Common.RecurringFeesServiceGetRecurringFeesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, xTeamId }: {
  limit?: number;
  page?: number;
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRecurringFeesServiceGetRecurringFeesKeyFn({ limit, page, xTeamId }, queryKey), queryFn: () => RecurringFeesService.getRecurringFees({ limit, page, xTeamId }) as TData, ...options });
export const useStatementsLayoutsServiceGetStatementLayouts = <TData = Common.StatementsLayoutsServiceGetStatementLayoutsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, xTeamId }: {
  limit?: number;
  page?: number;
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsLayoutsServiceGetStatementLayoutsKeyFn({ limit, page, xTeamId }, queryKey), queryFn: () => StatementsLayoutsService.getStatementLayouts({ limit, page, xTeamId }) as TData, ...options });
export const useStatementsLayoutsServiceGetStatmentLayout = <TData = Common.StatementsLayoutsServiceGetStatmentLayoutDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTeamId }: {
  id: string;
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsLayoutsServiceGetStatmentLayoutKeyFn({ id, xTeamId }, queryKey), queryFn: () => StatementsLayoutsService.getStatmentLayout({ id, xTeamId }) as TData, ...options });
export const useStatementsManagerServiceGetManagerStatementBatch = <TData = Common.StatementsManagerServiceGetManagerStatementBatchDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ collectionMode, dateRange, limit, page, status, xTeamId }: {
  collectionMode?: "auto" | "all" | "currentMonthOnly";
  dateRange?: string;
  limit?: number;
  page?: number;
  status?: "all" | "draft" | "inReview" | "void" | "preview" | "published";
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsManagerServiceGetManagerStatementBatchKeyFn({ collectionMode, dateRange, limit, page, status, xTeamId }, queryKey), queryFn: () => StatementsManagerService.getManagerStatementBatch({ collectionMode, dateRange, limit, page, status, xTeamId }) as TData, ...options });
export const useStatementsManagerServiceGetManagerStatements = <TData = Common.StatementsManagerServiceGetManagerStatementsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ collectionMode, dateRange, limit, page, status, xTeamId }: {
  collectionMode?: "auto" | "all" | "currentMonthOnly";
  dateRange?: string;
  limit?: number;
  page?: number;
  status?: "all" | "draft" | "inReview" | "void" | "preview" | "published";
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsManagerServiceGetManagerStatementsKeyFn({ collectionMode, dateRange, limit, page, status, xTeamId }, queryKey), queryFn: () => StatementsManagerService.getManagerStatements({ collectionMode, dateRange, limit, page, status, xTeamId }) as TData, ...options });
export const useStatementsManagerServiceGetManagerStatement = <TData = Common.StatementsManagerServiceGetManagerStatementDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ collectionMode, id, xTeamId }: {
  collectionMode?: "auto" | "all" | "currentMonthOnly";
  id: string;
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsManagerServiceGetManagerStatementKeyFn({ collectionMode, id, xTeamId }, queryKey), queryFn: () => StatementsManagerService.getManagerStatement({ collectionMode, id, xTeamId }) as TData, ...options });
export const useStatementsServiceGetOwnerStatementsBatch = <TData = Common.StatementsServiceGetOwnerStatementsBatchDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ collectionMode, limit, listingIds, month, ownerIds, ownershipPeriodIds, page, status, summaryType, templateId, viewAs, xTeamId }: {
  collectionMode?: "auto" | "all" | "currentMonthOnly";
  limit?: number;
  listingIds?: string;
  month?: string;
  ownerIds?: string;
  ownershipPeriodIds?: string;
  page?: number;
  status?: "all" | "draft" | "inReview" | "void" | "preview" | "published";
  summaryType?: "invoice" | "default";
  templateId?: string;
  viewAs?: "owner" | "manager";
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsServiceGetOwnerStatementsBatchKeyFn({ collectionMode, limit, listingIds, month, ownerIds, ownershipPeriodIds, page, status, summaryType, templateId, viewAs, xTeamId }, queryKey), queryFn: () => StatementsService.getOwnerStatementsBatch({ collectionMode, limit, listingIds, month, ownerIds, ownershipPeriodIds, page, status, summaryType, templateId, viewAs, xTeamId }) as TData, ...options });
export const useStatementsServiceGetOwnerStatement = <TData = Common.StatementsServiceGetOwnerStatementDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ collectionMode, id, summaryType, viewAs, xTeamId }: {
  collectionMode?: "auto" | "all" | "currentMonthOnly";
  id: string;
  summaryType?: "invoice" | "default";
  viewAs?: "owner" | "manager";
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsServiceGetOwnerStatementKeyFn({ collectionMode, id, summaryType, viewAs, xTeamId }, queryKey), queryFn: () => StatementsService.getOwnerStatement({ collectionMode, id, summaryType, viewAs, xTeamId }) as TData, ...options });
export const useStatementsServiceGetOwnerStatements = <TData = Common.StatementsServiceGetOwnerStatementsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ collectionMode, limit, listingIds, month, ownerIds, ownershipPeriodIds, page, search, status, templateId, xTeamId }: {
  collectionMode?: "auto" | "all" | "currentMonthOnly";
  limit?: number;
  listingIds?: string;
  month?: string;
  ownerIds?: string;
  ownershipPeriodIds?: string;
  page?: number;
  search?: string;
  status?: "all" | "draft" | "inReview" | "void" | "preview" | "published";
  templateId?: string;
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsServiceGetOwnerStatementsKeyFn({ collectionMode, limit, listingIds, month, ownerIds, ownershipPeriodIds, page, search, status, templateId, xTeamId }, queryKey), queryFn: () => StatementsService.getOwnerStatements({ collectionMode, limit, listingIds, month, ownerIds, ownershipPeriodIds, page, search, status, templateId, xTeamId }) as TData, ...options });
export const useStatementsServiceGetOwnerStatementPdf = <TData = Common.StatementsServiceGetOwnerStatementPdfDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ collectionMode, id, payoutReceivedByOwner, summaryType, xTeamId }: {
  collectionMode?: "auto" | "all" | "currentMonthOnly";
  id: string;
  payoutReceivedByOwner?: boolean;
  summaryType?: "invoice" | "default";
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatementsServiceGetOwnerStatementPdfKeyFn({ collectionMode, id, payoutReceivedByOwner, summaryType, xTeamId }, queryKey), queryFn: () => StatementsService.getOwnerStatementPdf({ collectionMode, id, payoutReceivedByOwner, summaryType, xTeamId }) as TData, ...options });
export const useTransactionsServiceGetTransactions = <TData = Common.TransactionsServiceGetTransactionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ date, includeLines, limit, page, type, uniqueRefs, xTeamId }: {
  date?: string;
  includeLines?: boolean;
  limit?: number;
  page?: number;
  type?: "deposit" | "expense" | "transfer";
  uniqueRefs?: string;
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTransactionsServiceGetTransactionsKeyFn({ date, includeLines, limit, page, type, uniqueRefs, xTeamId }, queryKey), queryFn: () => TransactionsService.getTransactions({ date, includeLines, limit, page, type, uniqueRefs, xTeamId }) as TData, ...options });
export const useTransactionsServiceGetTransaction = <TData = Common.TransactionsServiceGetTransactionDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTeamId }: {
  id: string;
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTransactionsServiceGetTransactionKeyFn({ id, xTeamId }, queryKey), queryFn: () => TransactionsService.getTransaction({ id, xTeamId }) as TData, ...options });
export const useConnectionsServiceGetConnection = <TData = Common.ConnectionsServiceGetConnectionDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTeamId }: {
  id: string;
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseConnectionsServiceGetConnectionKeyFn({ id, xTeamId }, queryKey), queryFn: () => ConnectionsService.getConnection({ id, xTeamId }) as TData, ...options });
export const useConnectionsServiceGetConnections = <TData = Common.ConnectionsServiceGetConnectionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ appId, limit, page, status, tenantId, xTeamId }: {
  appId?: string;
  limit?: number;
  page?: number;
  status?: string;
  tenantId?: string;
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseConnectionsServiceGetConnectionsKeyFn({ appId, limit, page, status, tenantId, xTeamId }, queryKey), queryFn: () => ConnectionsService.getConnections({ appId, limit, page, status, tenantId, xTeamId }) as TData, ...options });
export const useAccountsServiceGetAccounts = <TData = Common.AccountsServiceGetAccountsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, type, xTeamId }: {
  limit?: number;
  page?: number;
  type?: "ledger" | "bank" | "recurringFee";
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountsServiceGetAccountsKeyFn({ limit, page, type, xTeamId }, queryKey), queryFn: () => AccountsService.getAccounts({ limit, page, type, xTeamId }) as TData, ...options });
export const useRatesServiceGetRates = <TData = Common.RatesServiceGetRatesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, status, type, xTeamId }: {
  limit?: number;
  page?: number;
  status?: "active" | "inactive";
  type?: "markup" | "tax";
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRatesServiceGetRatesKeyFn({ limit, page, status, type, xTeamId }, queryKey), queryFn: () => RatesService.getRates({ limit, page, status, type, xTeamId }) as TData, ...options });
export const useContactsServiceGetContact = <TData = Common.ContactsServiceGetContactDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, xTeamId }: {
  id: string;
  xTeamId?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseContactsServiceGetContactKeyFn({ id, xTeamId }, queryKey), queryFn: () => ContactsService.getContact({ id, xTeamId }) as TData, ...options });
export const useContactsServiceGetContacts = <TData = Common.ContactsServiceGetContactsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ limit, page, status, xTeamId }: {
  limit?: number;
  page?: number;
  status?: "active" | "inactive";
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseContactsServiceGetContactsKeyFn({ limit, page, status, xTeamId }, queryKey), queryFn: () => ContactsService.getContacts({ limit, page, status, xTeamId }) as TData, ...options });
export const useMetricsServiceGetMetrics = <TData = Common.MetricsServiceGetMetricsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ connectionId, date, limit, page, type, xTeamId }: {
  connectionId?: string;
  date?: string;
  limit?: number;
  page?: number;
  type?: string;
  xTeamId?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseMetricsServiceGetMetricsKeyFn({ connectionId, date, limit, page, type, xTeamId }, queryKey), queryFn: () => MetricsService.getMetrics({ connectionId, date, limit, page, type, xTeamId }) as TData, ...options });
export const usePaymentsServiceBatchPayment = <TData = Common.PaymentsServiceBatchPaymentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { connectionId: string; data: { id?: string; uniqueRef?: string; etaAt?: string; paidAt?: string; currency: string; description: string; metadata?: { [key: string]: unknown; }; userdata?: { [key: string]: unknown; }; source?: { type: string; uniqueRef?: string; data: { [key: string]: unknown; }; }; lines: { id?: string; uniqueRef?: string; type: string; description?: string; reservationId?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; }[]; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { connectionId: string; data: { id?: string; uniqueRef?: string; etaAt?: string; paidAt?: string; currency: string; description: string; metadata?: { [key: string]: unknown; }; userdata?: { [key: string]: unknown; }; source?: { type: string; uniqueRef?: string; data: { [key: string]: unknown; }; }; lines: { id?: string; uniqueRef?: string; type: string; description?: string; reservationId?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; }[]; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => PaymentsService.batchPayment({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const usePaymentsServiceCreatePayment = <TData = Common.PaymentsServiceCreatePaymentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { id?: string; uniqueRef?: string; etaAt?: string; paidAt?: string; connectionId: string; currency: string; description: string; metadata?: { [key: string]: unknown; }; userdata?: { [key: string]: unknown; }; source?: { type: string; uniqueRef?: string; data: { [key: string]: unknown; }; }; lines: { id?: string; uniqueRef?: string; type: string; description?: string; reservationId?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { id?: string; uniqueRef?: string; etaAt?: string; paidAt?: string; connectionId: string; currency: string; description: string; metadata?: { [key: string]: unknown; }; userdata?: { [key: string]: unknown; }; source?: { type: string; uniqueRef?: string; data: { [key: string]: unknown; }; }; lines: { id?: string; uniqueRef?: string; type: string; description?: string; reservationId?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => PaymentsService.createPayment({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useBankAccountsServiceInitBankAccount = <TData = Common.BankAccountsServiceInitBankAccountMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { startingBankRecordId: string; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { startingBankRecordId: string; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => BankAccountsService.initBankAccount({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useReservationsServiceBatchReservations = <TData = Common.ReservationsServiceBatchReservationsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { connectionId: string; data: { currency?: string; uniqueRef?: string; guestName?: string; bookerName?: string; checkIn: string; checkOut: string; listingId?: string; listingRef?: string; status?: "cancelled" | "booked" | "inquiry"; bookedAt?: string; cancelledAt?: string; bookingPlatform?: string; confirmationCode: string; pmsReferenceCode?: string; guests?: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; cancelledAutobalanceStatus?: "active" | "inactive"; lines: { id?: string; uniqueRef?: string; type: string; description?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; id?: string; }[]; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { connectionId: string; data: { currency?: string; uniqueRef?: string; guestName?: string; bookerName?: string; checkIn: string; checkOut: string; listingId?: string; listingRef?: string; status?: "cancelled" | "booked" | "inquiry"; bookedAt?: string; cancelledAt?: string; bookingPlatform?: string; confirmationCode: string; pmsReferenceCode?: string; guests?: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; cancelledAutobalanceStatus?: "active" | "inactive"; lines: { id?: string; uniqueRef?: string; type: string; description?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; id?: string; }[]; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => ReservationsService.batchReservations({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useReservationsServiceCreateReservationJournalEntries = <TData = Common.ReservationsServiceCreateReservationJournalEntriesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { ids?: string[]; listingIds?: string[]; status?: "cancelled" | "booked" | "inquiry"; date?: string; dateField?: "bookedAt" | "checkIn" | "checkOut" | "payment" | "intersection"; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { ids?: string[]; listingIds?: string[]; status?: "cancelled" | "booked" | "inquiry"; date?: string; dateField?: "bookedAt" | "checkIn" | "checkOut" | "payment" | "intersection"; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => ReservationsService.createReservationJournalEntries({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useReservationsServiceCreateReservation = <TData = Common.ReservationsServiceCreateReservationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { currency?: string; uniqueRef?: string; connectionId: string; guestName?: string; bookerName?: string; checkIn: string; checkOut: string; listingId?: string; listingRef?: string; status?: "cancelled" | "booked" | "inquiry"; bookedAt?: string; cancelledAt?: string; bookingPlatform?: string; confirmationCode: string; pmsReferenceCode?: string; guests?: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; cancelledAutobalanceStatus?: "active" | "inactive"; lines: { id?: string; uniqueRef?: string; type: string; description?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { currency?: string; uniqueRef?: string; connectionId: string; guestName?: string; bookerName?: string; checkIn: string; checkOut: string; listingId?: string; listingRef?: string; status?: "cancelled" | "booked" | "inquiry"; bookedAt?: string; cancelledAt?: string; bookingPlatform?: string; confirmationCode: string; pmsReferenceCode?: string; guests?: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; cancelledAutobalanceStatus?: "active" | "inactive"; lines: { id?: string; uniqueRef?: string; type: string; description?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => ReservationsService.createReservation({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useListingsOwnershipPeriodsServiceCreateListingOwnership = <TData = Common.ListingsOwnershipPeriodsServiceCreateListingOwnershipMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { listingId: string; startAt?: string; endAt?: string; members: { contactId: string; split?: number; }[]; onConflict?: "error" | "updateExisting" | "adjustInsertingItem"; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { listingId: string; startAt?: string; endAt?: string; members: { contactId: string; split?: number; }[]; onConflict?: "error" | "updateExisting" | "adjustInsertingItem"; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => ListingsOwnershipPeriodsService.createListingOwnership({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useListingsServiceBatchListings = <TData = Common.ListingsServiceBatchListingsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { connectionId: string; data: { uniqueRef?: string; name: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; status?: "active" | "inactive"; defaultCurrency?: string; id?: string; }[]; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { connectionId: string; data: { uniqueRef?: string; name: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; status?: "active" | "inactive"; defaultCurrency?: string; id?: string; }[]; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => ListingsService.batchListings({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useListingsServiceCreateListing = <TData = Common.ListingsServiceCreateListingMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { uniqueRef?: string; connectionId?: string; name: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; status?: "active" | "inactive"; defaultCurrency?: string; activeOwnership?: { startAt?: string; endAt?: string; members: { contactId: string; split?: number; }[]; onConflict?: "error" | "updateExisting" | "adjustInsertingItem"; }; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { uniqueRef?: string; connectionId?: string; name: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; status?: "active" | "inactive"; defaultCurrency?: string; activeOwnership?: { startAt?: string; endAt?: string; members: { contactId: string; split?: number; }[]; onConflict?: "error" | "updateExisting" | "adjustInsertingItem"; }; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => ListingsService.createListing({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useTeamsServiceCreateTeam = <TData = Common.TeamsServiceCreateTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { defaultCurrency?: string; name: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; isOnboarding?: boolean; logo?: string; copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; addMembers?: { userId: string; role: string; }[]; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { defaultCurrency?: string; name: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; isOnboarding?: boolean; logo?: string; copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; addMembers?: { userId: string; role: string; }[]; };
}, TContext>({ mutationFn: ({ requestBody }) => TeamsService.createTeam({ requestBody }) as unknown as Promise<TData>, ...options });
export const useTeamsServiceGenerateTeamData = <TData = Common.TeamsServiceGenerateTeamDataMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, xTeamId }) => TeamsService.generateTeamData({ id, xTeamId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesListingPeriodsServiceCreateRecurringFeeListingPeriod = <TData = Common.RecurringFeesListingPeriodsServiceCreateRecurringFeeListingPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExisting" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExisting" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => RecurringFeesListingPeriodsService.createRecurringFeeListingPeriod({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesServiceCreateRecurringFee = <TData = Common.RecurringFeesServiceCreateRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { uniqueRef?: string; name: string; formula: string; icon?: string; type: "managementFee" | "additionalFee"; taxRateId?: string; taxBehavior?: "excluded" | "included"; trigger?: { per: "reservation"; value: "bookedAt" | "checkIn" | "checkOut" | "cancelledAt" | "proRata"; } | { per: "schedule"; value: string; }; status?: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExisting" | "adjustInsertingItem" | "closeExistingPeriods"; }[]; bookingChannels?: string[]; bookingChannelsFilter?: { type: "include" | "exclude"; value: string[]; }; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { uniqueRef?: string; name: string; formula: string; icon?: string; type: "managementFee" | "additionalFee"; taxRateId?: string; taxBehavior?: "excluded" | "included"; trigger?: { per: "reservation"; value: "bookedAt" | "checkIn" | "checkOut" | "cancelledAt" | "proRata"; } | { per: "schedule"; value: string; }; status?: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExisting" | "adjustInsertingItem" | "closeExistingPeriods"; }[]; bookingChannels?: string[]; bookingChannelsFilter?: { type: "include" | "exclude"; value: string[]; }; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => RecurringFeesService.createRecurringFee({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useStatementsLayoutsServiceCreateStatementLayout = <TData = Common.StatementsLayoutsServiceCreateStatementLayoutMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { name: string; summaryType?: "invoice" | "default"; activeListingIds?: string[]; isDefault?: boolean; netRevenueSections: { title: string; columns: { name: string; type: "accounts" | "subTotal" | "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; otherSections: { title: string; accountIds?: string[]; visible?: boolean; hideOnPortal?: boolean; }[]; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { name: string; summaryType?: "invoice" | "default"; activeListingIds?: string[]; isDefault?: boolean; netRevenueSections: { title: string; columns: { name: string; type: "accounts" | "subTotal" | "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; otherSections: { title: string; accountIds?: string[]; visible?: boolean; hideOnPortal?: boolean; }[]; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => StatementsLayoutsService.createStatementLayout({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useStatementsManagerServiceCreateManagerStatement = <TData = Common.StatementsManagerServiceCreateManagerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { month: string; status?: "draft" | "inReview" | "void" | "published"; collectionMode?: "auto" | "all" | "currentMonthOnly"; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { month: string; status?: "draft" | "inReview" | "void" | "published"; collectionMode?: "auto" | "all" | "currentMonthOnly"; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => StatementsManagerService.createManagerStatement({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useStatementsManagerServicePayManagerStatement = <TData = Common.StatementsManagerServicePayManagerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { amount?: number; lineAccountId?: string; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { amount?: number; lineAccountId?: string; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => StatementsManagerService.payManagerStatement({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useStatementsServiceCreateOwnerStatement = <TData = Common.StatementsServiceCreateOwnerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { month: string; status?: "draft" | "inReview" | "void" | "published"; collectionMode?: "auto" | "all" | "currentMonthOnly"; listingIds?: string[]; listingPeriodIds?: string[]; sync?: boolean; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { month: string; status?: "draft" | "inReview" | "void" | "published"; collectionMode?: "auto" | "all" | "currentMonthOnly"; listingIds?: string[]; listingPeriodIds?: string[]; sync?: boolean; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => StatementsService.createOwnerStatement({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useStatementsServicePayOwnerStatement = <TData = Common.StatementsServicePayOwnerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { amount?: number; lineAccountId?: string; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { amount?: number; lineAccountId?: string; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => StatementsService.payOwnerStatement({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useTransactionsServiceCreateTransactionJournalEntries = <TData = Common.TransactionsServiceCreateTransactionJournalEntriesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { ids?: string[]; listingIds?: string[]; date?: string; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { ids?: string[]; listingIds?: string[]; date?: string; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => TransactionsService.createTransactionJournalEntries({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useTransactionsServiceCreateTransaction = <TData = Common.TransactionsServiceCreateTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { description: string; accountId?: string; accountRef?: string; contactId?: string; uniqueRef?: string; type: "deposit" | "expense" | "transfer"; date: string; currency: string; lines: { uniqueRef?: string; description: string; accountId?: string; accountRef?: string; assignment?: "deposit" | "transfer" | "accountsReceivable" | "accountsPayable" | "deferredRevenue" | "deposit_refund" | "deposit_reserve" | "deposit_channelFee" | "deposit_merchantFee" | "expense_markup"; amount: number; additions?: { accountId?: string; accountRef?: string; type: "markup" | "tax" | "markupTax"; description?: string; behavior?: "excluded" | "included"; percentage?: number; amount?: number; rateId?: string; }[]; listingId?: string; reservationId?: string; ownerStatementId?: string; party?: "manager" | "owners"; contactId?: string; }[]; payment?: { bankRecordIds?: string[]; status?: "unpaid" | "underpaid" | "overpaid" | "paid"; date?: string; }; connectionId?: string; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { description: string; accountId?: string; accountRef?: string; contactId?: string; uniqueRef?: string; type: "deposit" | "expense" | "transfer"; date: string; currency: string; lines: { uniqueRef?: string; description: string; accountId?: string; accountRef?: string; assignment?: "deposit" | "transfer" | "accountsReceivable" | "accountsPayable" | "deferredRevenue" | "deposit_refund" | "deposit_reserve" | "deposit_channelFee" | "deposit_merchantFee" | "expense_markup"; amount: number; additions?: { accountId?: string; accountRef?: string; type: "markup" | "tax" | "markupTax"; description?: string; behavior?: "excluded" | "included"; percentage?: number; amount?: number; rateId?: string; }[]; listingId?: string; reservationId?: string; ownerStatementId?: string; party?: "manager" | "owners"; contactId?: string; }[]; payment?: { bankRecordIds?: string[]; status?: "unpaid" | "underpaid" | "overpaid" | "paid"; date?: string; }; connectionId?: string; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => TransactionsService.createTransaction({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useConnectionsServiceCreateConnection = <TData = Common.ConnectionsServiceCreateConnectionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { name?: string; appId: string; credentials?: unknown; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { name?: string; appId: string; credentials?: unknown; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => ConnectionsService.createConnection({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useConnectionsServiceConnectConnection = <TData = Common.ConnectionsServiceConnectConnectionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { appId: string; redirectTo?: string; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { appId: string; redirectTo?: string; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => ConnectionsService.connectConnection({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useRatesServiceCreateRate = <TData = Common.RatesServiceCreateRateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { name: string; currency?: string; percentage: number; type?: "markup" | "tax"; status?: "active" | "inactive"; accountId?: string; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { name: string; currency?: string; percentage: number; type?: "markup" | "tax"; status?: "active" | "inactive"; accountId?: string; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => RatesService.createRate({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useContactsServiceCreateContact = <TData = Common.ContactsServiceCreateContactMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; type: "owner" | "vendor"; email?: string; firstName?: string; name?: string; phone?: string; connectionId?: string; ownershipPeriods?: { listingId: string; split: number; startAt: string; endAt?: string; }[]; companyType?: "c_corporation" | "limited_liability_company" | "partnership" | "s_corporation" | "trust_estate"; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; type: "owner" | "vendor"; email?: string; firstName?: string; name?: string; phone?: string; connectionId?: string; ownershipPeriods?: { listingId: string; split: number; startAt: string; endAt?: string; }[]; companyType?: "c_corporation" | "limited_liability_company" | "partnership" | "s_corporation" | "trust_estate"; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => ContactsService.createContact({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const usePaymentsServiceUpdatePayment = <TData = Common.PaymentsServiceUpdatePaymentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; etaAt?: string; paidAt?: string; connectionId?: string; currency?: string; description?: string; metadata?: { [key: string]: unknown; }; userdata?: { [key: string]: unknown; }; source?: { type: string; uniqueRef?: string; data: { [key: string]: unknown; }; }; lines?: { id?: string; uniqueRef?: string; type: string; description?: string; reservationId?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; etaAt?: string; paidAt?: string; connectionId?: string; currency?: string; description?: string; metadata?: { [key: string]: unknown; }; userdata?: { [key: string]: unknown; }; source?: { type: string; uniqueRef?: string; data: { [key: string]: unknown; }; }; lines?: { id?: string; uniqueRef?: string; type: string; description?: string; reservationId?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => PaymentsService.updatePayment({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useReservationsServiceUpdateReservation = <TData = Common.ReservationsServiceUpdateReservationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { currency?: string; uniqueRef?: string; connectionId?: string; guestName?: string; bookerName?: string; checkIn?: string; checkOut?: string; listingId?: string; listingRef?: string; status?: "cancelled" | "booked" | "inquiry"; bookedAt?: string; cancelledAt?: string; bookingPlatform?: string; confirmationCode?: string; pmsReferenceCode?: string; guests?: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; cancelledAutobalanceStatus?: "active" | "inactive"; lines?: { id?: string; uniqueRef?: string; type: string; description?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { currency?: string; uniqueRef?: string; connectionId?: string; guestName?: string; bookerName?: string; checkIn?: string; checkOut?: string; listingId?: string; listingRef?: string; status?: "cancelled" | "booked" | "inquiry"; bookedAt?: string; cancelledAt?: string; bookingPlatform?: string; confirmationCode?: string; pmsReferenceCode?: string; guests?: number; userdata?: { [key: string]: unknown; }; metadata?: { [key: string]: unknown; }; cancelledAutobalanceStatus?: "active" | "inactive"; lines?: { id?: string; uniqueRef?: string; type: string; description?: string; reservationRef?: string; amount: number; accountId?: string; accountRef?: string; connectionId?: string; metadata?: { [key: string]: unknown; }; }[]; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => ReservationsService.updateReservation({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useListingsOwnershipPeriodsServiceUpdateListingOwnership = <TData = Common.ListingsOwnershipPeriodsServiceUpdateListingOwnershipMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { listingId?: string; startAt?: string; endAt?: string; members?: { contactId: string; split?: number; }[]; onConflict?: "error" | "updateExisting" | "adjustInsertingItem"; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { listingId?: string; startAt?: string; endAt?: string; members?: { contactId: string; split?: number; }[]; onConflict?: "error" | "updateExisting" | "adjustInsertingItem"; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => ListingsOwnershipPeriodsService.updateListingOwnership({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useListingsServiceUpdateListing = <TData = Common.ListingsServiceUpdateListingMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; connectionId?: string; name?: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; status?: "active" | "inactive"; defaultCurrency?: string; activeOwnership?: { startAt?: string; endAt?: string; members: { contactId: string; split?: number; }[]; onConflict?: "error" | "updateExisting" | "adjustInsertingItem"; }; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; connectionId?: string; name?: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; status?: "active" | "inactive"; defaultCurrency?: string; activeOwnership?: { startAt?: string; endAt?: string; members: { contactId: string; split?: number; }[]; onConflict?: "error" | "updateExisting" | "adjustInsertingItem"; }; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => ListingsService.updateListing({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useTeamsServiceUpdateTeam = <TData = Common.TeamsServiceUpdateTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { defaultCurrency?: string; name?: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; isOnboarding?: boolean; logo?: string; copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; addMembers?: { userId: string; role: string; }[]; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { defaultCurrency?: string; name?: string; address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; phone?: string; email?: string; taxId?: string; partnerId?: string; companyName?: string; createdAt?: string; isOnboarding?: boolean; logo?: string; copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; addMembers?: { userId: string; role: string; }[]; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => TeamsService.updateTeam({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesListingPeriodsServiceUpdateRecurringFeeListingPeriod = <TData = Common.RecurringFeesListingPeriodsServiceUpdateRecurringFeeListingPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { startAt?: string; endAt?: string; rate?: number; onConflict?: "error" | "updateExisting" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { startAt?: string; endAt?: string; rate?: number; onConflict?: "error" | "updateExisting" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => RecurringFeesListingPeriodsService.updateRecurringFeeListingPeriod({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesServiceUpdateMultipleRecurringFeeListingPeriod = <TData = Common.RecurringFeesServiceUpdateMultipleRecurringFeeListingPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { updates: { startAt?: string; endAt?: string; rate?: number; id: string; }[]; onConflict?: "error" | "updateExisting" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { updates: { startAt?: string; endAt?: string; rate?: number; id: string; }[]; onConflict?: "error" | "updateExisting" | "adjustInsertingItem" | "closeExistingPeriods"; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ requestBody, xTeamId }) => RecurringFeesService.updateMultipleRecurringFeeListingPeriod({ requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesServiceUpdateRecurringFee = <TData = Common.RecurringFeesServiceUpdateRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; name?: string; formula?: string; icon?: string; type?: "managementFee" | "additionalFee"; taxRateId?: string; taxBehavior?: "excluded" | "included"; trigger?: { per: "reservation"; value: "bookedAt" | "checkIn" | "checkOut" | "cancelledAt" | "proRata"; } | { per: "schedule"; value: string; }; status?: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExisting" | "adjustInsertingItem" | "closeExistingPeriods"; }[]; bookingChannels?: string[]; bookingChannelsFilter?: { type: "include" | "exclude"; value: string[]; }; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { uniqueRef?: string; name?: string; formula?: string; icon?: string; type?: "managementFee" | "additionalFee"; taxRateId?: string; taxBehavior?: "excluded" | "included"; trigger?: { per: "reservation"; value: "bookedAt" | "checkIn" | "checkOut" | "cancelledAt" | "proRata"; } | { per: "schedule"; value: string; }; status?: "active" | "inactive"; rateType?: "flat" | "percentage"; creditAccountId?: string; debitAccountId?: string; creditParty?: "manager" | "owners"; debitParty?: "manager" | "owners"; enabledListings?: { listingId: string; startAt: string; endAt?: string; rate: number; recurringFeeId: string; onConflict?: "error" | "updateExisting" | "adjustInsertingItem" | "closeExistingPeriods"; }[]; bookingChannels?: string[]; bookingChannelsFilter?: { type: "include" | "exclude"; value: string[]; }; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => RecurringFeesService.updateRecurringFee({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useStatementsLayoutsServiceUpdateStatementLayout = <TData = Common.StatementsLayoutsServiceUpdateStatementLayoutMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { name?: string; summaryType?: "invoice" | "default"; activeListingIds?: string[]; isDefault?: boolean; netRevenueSections?: { title: string; columns: { name: string; type: "accounts" | "subTotal" | "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; otherSections?: { title: string; accountIds?: string[]; visible?: boolean; hideOnPortal?: boolean; }[]; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { name?: string; summaryType?: "invoice" | "default"; activeListingIds?: string[]; isDefault?: boolean; netRevenueSections?: { title: string; columns: { name: string; type: "accounts" | "subTotal" | "field" | "formula" | "formula.percentage" | "formula.currency"; value: string; visible?: boolean; hideOnPortal?: boolean; locked?: boolean; }[]; }[]; otherSections?: { title: string; accountIds?: string[]; visible?: boolean; hideOnPortal?: boolean; }[]; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => StatementsLayoutsService.updateStatementLayout({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useStatementsManagerServiceUpdateManagerStatement = <TData = Common.StatementsManagerServiceUpdateManagerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { collectionMode?: "auto" | "all" | "currentMonthOnly"; status: "draft" | "inReview" | "void" | "published"; sync?: boolean; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { collectionMode?: "auto" | "all" | "currentMonthOnly"; status: "draft" | "inReview" | "void" | "published"; sync?: boolean; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => StatementsManagerService.updateManagerStatement({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useStatementsServiceUpdateOwnerStatement = <TData = Common.StatementsServiceUpdateOwnerStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { collectionMode?: "auto" | "all" | "currentMonthOnly"; status: "draft" | "inReview" | "void" | "published"; sync?: boolean; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { collectionMode?: "auto" | "all" | "currentMonthOnly"; status: "draft" | "inReview" | "void" | "published"; sync?: boolean; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => StatementsService.updateOwnerStatement({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useTransactionsServiceUpdateTransaction = <TData = Common.TransactionsServiceUpdateTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { description?: string; accountId?: string; accountRef?: string; contactId?: string; uniqueRef?: string; type?: "deposit" | "expense" | "transfer"; date?: string; currency?: string; lines?: { uniqueRef?: string; description: string; accountId?: string; accountRef?: string; assignment?: "deposit" | "transfer" | "accountsReceivable" | "accountsPayable" | "deferredRevenue" | "deposit_refund" | "deposit_reserve" | "deposit_channelFee" | "deposit_merchantFee" | "expense_markup"; amount: number; additions?: { accountId?: string; accountRef?: string; type: "markup" | "tax" | "markupTax"; description?: string; behavior?: "excluded" | "included"; percentage?: number; amount?: number; rateId?: string; }[]; listingId?: string; reservationId?: string; ownerStatementId?: string; party?: "manager" | "owners"; contactId?: string; }[]; payment?: { bankRecordIds?: string[]; status?: "unpaid" | "underpaid" | "overpaid" | "paid"; date?: string; }; connectionId?: string; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { description?: string; accountId?: string; accountRef?: string; contactId?: string; uniqueRef?: string; type?: "deposit" | "expense" | "transfer"; date?: string; currency?: string; lines?: { uniqueRef?: string; description: string; accountId?: string; accountRef?: string; assignment?: "deposit" | "transfer" | "accountsReceivable" | "accountsPayable" | "deferredRevenue" | "deposit_refund" | "deposit_reserve" | "deposit_channelFee" | "deposit_merchantFee" | "expense_markup"; amount: number; additions?: { accountId?: string; accountRef?: string; type: "markup" | "tax" | "markupTax"; description?: string; behavior?: "excluded" | "included"; percentage?: number; amount?: number; rateId?: string; }[]; listingId?: string; reservationId?: string; ownerStatementId?: string; party?: "manager" | "owners"; contactId?: string; }[]; payment?: { bankRecordIds?: string[]; status?: "unpaid" | "underpaid" | "overpaid" | "paid"; date?: string; }; connectionId?: string; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => TransactionsService.updateTransaction({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useRatesServiceUpdateRate = <TData = Common.RatesServiceUpdateRateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { name?: string; currency?: string; percentage?: number; type?: "markup" | "tax"; status?: "active" | "inactive"; accountId?: string; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { name?: string; currency?: string; percentage?: number; type?: "markup" | "tax"; status?: "active" | "inactive"; accountId?: string; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => RatesService.updateRate({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useBankRecordsServiceUpdateBankRecords = <TData = Common.BankRecordsServiceUpdateBankRecordsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { reconciledTransactionIds: string[]; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { reconciledTransactionIds: string[]; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => BankRecordsService.updateBankRecords({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useContactsServiceUpdateContact = <TData = Common.ContactsServiceUpdateContactMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; type?: "owner" | "vendor"; email?: string; firstName?: string; name?: string; phone?: string; connectionId?: string; ownershipPeriods?: { listingId: string; split: number; startAt: string; endAt?: string; }[]; companyType?: "c_corporation" | "limited_liability_company" | "partnership" | "s_corporation" | "trust_estate"; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { address?: { line1?: string; line2?: string; city?: string; state?: string; postalCode?: string; country?: string; stateCode?: string; countryCode?: string; }; type?: "owner" | "vendor"; email?: string; firstName?: string; name?: string; phone?: string; connectionId?: string; ownershipPeriods?: { listingId: string; split: number; startAt: string; endAt?: string; }[]; companyType?: "c_corporation" | "limited_liability_company" | "partnership" | "s_corporation" | "trust_estate"; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => ContactsService.updateContact({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const useTeamsServiceInitTeam = <TData = Common.TeamsServiceInitTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; };
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { copyFromTeamId?: string; copyStatementLayouts?: boolean; copyRecurringFees?: boolean; };
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, requestBody, xTeamId }) => TeamsService.initTeam({ id, requestBody, xTeamId }) as unknown as Promise<TData>, ...options });
export const usePaymentsServiceDeletePayment = <TData = Common.PaymentsServiceDeletePaymentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, xTeamId }) => PaymentsService.deletePayment({ id, xTeamId }) as unknown as Promise<TData>, ...options });
export const useReservationsServiceDeleteReservation = <TData = Common.ReservationsServiceDeleteReservationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTeamId }) => ReservationsService.deleteReservation({ id, onLocked, xTeamId }) as unknown as Promise<TData>, ...options });
export const useListingsOwnershipPeriodsServiceDeleteListingOwnership = <TData = Common.ListingsOwnershipPeriodsServiceDeleteListingOwnershipMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTeamId }) => ListingsOwnershipPeriodsService.deleteListingOwnership({ id, onLocked, xTeamId }) as unknown as Promise<TData>, ...options });
export const useListingsServiceDeleteListing = <TData = Common.ListingsServiceDeleteListingMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTeamId }) => ListingsService.deleteListing({ id, onLocked, xTeamId }) as unknown as Promise<TData>, ...options });
export const useTeamsServiceDeleteTeam = <TData = Common.TeamsServiceDeleteTeamMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  force?: boolean;
  id: string;
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  force?: boolean;
  id: string;
  xTeamId?: string;
}, TContext>({ mutationFn: ({ force, id, xTeamId }) => TeamsService.deleteTeam({ force, id, xTeamId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesListingPeriodsServiceDeleteRecurringFeeListingPeriod = <TData = Common.RecurringFeesListingPeriodsServiceDeleteRecurringFeeListingPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTeamId }) => RecurringFeesListingPeriodsService.deleteRecurringFeeListingPeriod({ id, onLocked, xTeamId }) as unknown as Promise<TData>, ...options });
export const useRecurringFeesServiceDeleteRecurringFee = <TData = Common.RecurringFeesServiceDeleteRecurringFeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, xTeamId }) => RecurringFeesService.deleteRecurringFee({ id, xTeamId }) as unknown as Promise<TData>, ...options });
export const useStatementsLayoutsServiceDeleteStatementLayout = <TData = Common.StatementsLayoutsServiceDeleteStatementLayoutMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, xTeamId }) => StatementsLayoutsService.deleteStatementLayout({ id, xTeamId }) as unknown as Promise<TData>, ...options });
export const useStatementsServiceDeleteStatement = <TData = Common.StatementsServiceDeleteStatementMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, xTeamId }) => StatementsService.deleteStatement({ id, xTeamId }) as unknown as Promise<TData>, ...options });
export const useTransactionsServiceDeleteTransaction = <TData = Common.TransactionsServiceDeleteTransactionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTeamId }) => TransactionsService.deleteTransaction({ id, onLocked, xTeamId }) as unknown as Promise<TData>, ...options });
export const useConnectionsServiceDeleteConnection = <TData = Common.ConnectionsServiceDeleteConnectionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTeamId }) => ConnectionsService.deleteConnection({ id, onLocked, xTeamId }) as unknown as Promise<TData>, ...options });
export const useAccountsServiceDeleteAccount = <TData = Common.AccountsServiceDeleteAccountMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTeamId }) => AccountsService.deleteAccount({ id, onLocked, xTeamId }) as unknown as Promise<TData>, ...options });
export const useRatesServiceDeleteRate = <TData = Common.RatesServiceDeleteRateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTeamId }) => RatesService.deleteRate({ id, onLocked, xTeamId }) as unknown as Promise<TData>, ...options });
export const useContactsServiceDeleteContact = <TData = Common.ContactsServiceDeleteContactMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  onLocked?: "error" | "archive";
  xTeamId?: string;
}, TContext>({ mutationFn: ({ id, onLocked, xTeamId }) => ContactsService.deleteContact({ id, onLocked, xTeamId }) as unknown as Promise<TData>, ...options });
