import { Icon } from '@finalytic/icons';
import { NavbarRoute } from '../layout/navbar';

export const GlobalMappingsNavbarItem = ({
  iconSize,
}: {
  iconSize: number;
}) => {
  return (
    <NavbarRoute
      title="Mappings"
      link="/mappings"
      icon={<Icon icon="RouteIcon" size={iconSize} />}
    />
  );
};

// function useMappingCountQuery() {
//   const [{ id: teamId, partnerId }] = useTeam();
//   const { isVrpAdmin } = useTeamRole();
//   const setMappings = useMissingMappingStore((store) => store.setMappings);

//   const { mappings, loading: loadingMappings } = useGlobalMappingTabsQuery();

//   const hasMappings = mappings.length > 0;

//   const { data: counts, isLoading: loadingCounts } = useQuery(
//     (q, args) => {
//       const counts: {
//         [settingKey: string]: {
//           count: number;
//           hasDefaultSetting: boolean;
//         };
//       } = {};

//       for (const { automation, mapping, settingKey } of mappings) {
//         const {
//           id: automationId,
//           leftConnection,
//           rightConnection,
//         } = automation;
//         const leftConnectionId = leftConnection.id;
//         const rightConnectionId = rightConnection.id;

//         const count =
//           getMappingRowData(
//             q,
//             {
//               search: undefined,
//               mapping: 'unmapped',
//               limit: 0,
//               offset: 0,
//               automationId: automationId,
//               leftConnectionId: leftConnectionId,
//               rightConnectionId: rightConnectionId,
//               settingKey: settingKey,
//               teamId: args.teamId,
//               isLocal: mapping.scope === 'local',
//               leftSchema: mapping.left.schema,
//               leftParams: mapping.left.params || mapping.params || {},
//               // leftParams: {},
//               rightSchema: mapping.right.schema,
//               partnerId: args.partnerId,
//               isVrpAdmin: args.isVrpAdmin,
//               connections: {
//                 [leftConnection.appId!]: leftConnection.id,
//                 [rightConnection.appId!]: rightConnection.id,
//               },
//             },
//             {
//               emptyList: true,
//             }
//           )?.aggregate || 0;

//         const defaultSetting = q
//           .setting({
//             limit: 1,
//             where: {
//               tenant_id: { _eq: args.teamId },
//               // automationId: { _eq: args.automationId },
//               key: { _eq: settingKey },
//               target: { _eq: '*' },
//               localAutomationId: { _is_null: true },
//               _or: whereSettingAcrossAutomations({
//                 leftConnectionId: leftConnectionId,
//                 leftSchema: undefined,
//                 rightConnectionId: rightConnectionId,
//                 rightSchema: mapping.right.schema,
//                 automationId: automationId,
//               }),
//             },
//           })
//           .map((x) => x.id)[0];

//         counts[settingKey] = {
//           count,
//           hasDefaultSetting: !!defaultSetting,
//         };
//       }

//       return counts;
//     },
//     {
//       skip: !hasMappings,
//       queryKey: 'settings',
//       variables: {
//         mappings,
//         teamId,
//         partnerId,
//         isVrpAdmin,
//       },
//     }
//   );

//   useEffect(() => {
//     if (counts) setMappings(teamId, counts);
//   }, [counts, setMappings, teamId]);

//   return {
//     count: sum(Object.values(counts || {}).map((x) => x.count)),
//     loading: loadingMappings || loadingCounts,
//   };
// }
