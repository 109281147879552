import { DepositFormInputs } from '../_hooks/useDepositForm';

export const getDepositReservationLinesCentTotal = (
  lines: DepositFormInputs['reservationLines']
) => {
  return lines.reduce((acc, line) => {
    // add all payments
    if (line.type === 'payment') {
      return (
        acc +
        line.centTotal -
        line.channelFeeCentTotal -
        line.merchantFeeCentTotal
      );
    }

    if (line.type === 'resolution') {
      return acc + line.centTotal - line.merchantFeeCentTotal;
    }

    return acc;
  }, 0);
};
