import { IconButton } from '@finalytic/components';
import { useMe, useTeam } from '@finalytic/data';
import { useQuery } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator, type SelectItem } from '@finalytic/ui';
import { utc } from '@finalytic/utils';
import {
  Combobox,
  Input,
  InputBase,
  LoadingOverlay,
  SegmentedControl,
  Text,
  useCombobox,
} from '@mantine/core';
import {
  getListingAddress,
  orderByListing,
  whereListings,
  whereReservations,
} from '@vrplatform/ui-common';
import { useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useExpenseForm } from '../useExpenseForm';

type SelectType = 'listing' | 'reservation';

function useDropdownQuery(search: string, type: SelectType) {
  const [{ id: teamId }] = useTeam();
  const { id: meId } = useMe();

  return useQuery(
    (q, args) => {
      const limit = 30;

      if (args.type === 'reservation') {
        const where = whereReservations({
          search: args.search,
          currentTeamId: args.teamId,
          dashboard: 'propertyManager',
          meId: args.meId,
          partnerTeamIds: [],
          includeLines: false,
        });

        return q
          .reservations({
            where,
            order_by: [{ checkIn: 'desc_nulls_last' }],
            limit,
          })
          .map<SelectItem>((res) => ({
            value: res.id,
            label: res.guestName || 'No name',
            description: `${utc(res.checkIn).format('ll')} - ${utc(
              res.checkOut
            ).format('ll')}${res.guests && ` - ${res.guests} guests`}`,
          }));
      }

      const where = whereListings({
        search: args.search,
        currentTeamId: args.teamId,
        dashboard: 'propertyManager',
        GL: true,
        meId: args.meId,
        partnerTeamIds: [],
      });

      return q
        .listings({
          where,
          order_by: [orderByListing],
          limit,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: res.calculated_title || 'No name',
          description: getListingAddress(res).full,
        }));
    },
    {
      skip: !teamId,
      queryKey: ['listing', 'reservations'],
      keepPreviousData: true,
      variables: {
        teamId,
        search: search?.trim(),
        type,
        meId,
      },
    }
  );
}

export const ExpenseListingReservationSelect = ({
  index,
}: { index: number }) => {
  const methods = useExpenseForm();
  const [search, setSearch] = useState('');

  const reservationId = useWatch({
    control: methods.control,
    name: `lines.${index}.reservationId`,
  });

  const [type, setType] = useState<SelectType>(
    reservationId ? 'reservation' : 'listing'
  );

  const { data: listData = [], isLoading: loadingList } = useDropdownQuery(
    search,
    type
  );

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  return (
    <Controller
      control={methods.control}
      name={
        type === 'reservation'
          ? `lines.${index}.reservationId`
          : `lines.${index}.listingId`
      }
      rules={{
        required: `${type === 'reservation' ? 'Reservation' : 'Listing'} is required`,
      }}
      render={({ field, fieldState }) => {
        const toggleType = (t?: SelectType) => {
          setType(t || (type === 'listing' ? 'reservation' : 'listing'));
          methods.setValue(`lines.${index}.listingId`, null);
          methods.setValue(`lines.${index}.reservationId`, null);
          field.onChange(null);
        };

        const { data, isLoading: loading } = useQuery(
          (q, args) => {
            if (!args.value) return null;

            if (type === 'reservation')
              return q
                .reservations({
                  where: { id: { _eq: args.value } },
                  limit: 1,
                })
                .map<SelectItem>((item) => ({
                  label: item.guestName || 'No name',
                  value: item.id!,
                }))[0];

            return q
              .listings({
                where: {
                  id: { _eq: args.value },
                },
                limit: 1,
              })
              .map<SelectItem>((item) => ({
                label: item.calculated_title || 'No name',
                value: item.id!,
              }))[0];
          },
          {
            keepPreviousData: true,
            variables: {
              value: field.value,
              type,
            },
          }
        );

        const currentValue = field.value ? data || null : null;

        const options = listData.map((item) => (
          <Combobox.Option value={item.value} key={item.value}>
            {item.label}
            {item.description && (
              <>
                <br />
                <Text span c="gray" size="xs">
                  {item.description}
                </Text>
              </>
            )}
          </Combobox.Option>
        ));

        return (
          <Combobox
            store={combobox}
            withinPortal
            onOptionSubmit={(val) => {
              field.onChange(val);
              combobox.closeDropdown();
            }}
            withArrow
            width={400}
            position="bottom"
            shadow="md"
          >
            <Combobox.Target>
              <InputBase
                component="button"
                error={fieldState.error?.message}
                type="button"
                pointer
                rightSection={
                  <Icon
                    icon="ChevronIcon"
                    size={18}
                    color={(theme) => theme.colors.gray[4]}
                  />
                }
                radius="md"
                onClick={() => combobox.toggleDropdown()}
                rightSectionPointerEvents="none"
                sx={(theme) => ({
                  width: 250,
                  button: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    '&:focus': {
                      boxShadow: `0px 0px 0px 2px ${
                        theme.colors[
                          fieldState.error ? 'red' : theme.primaryColor
                        ][4]
                      }40`,
                      borderColor: fieldState.error
                        ? theme.colors.red[6]
                        : theme.colors[theme.primaryColor][6],
                    },
                  },
                })}
                leftSection={
                  loading ? (
                    <LoadingIndicator size="xs" />
                  ) : (
                    <IconButton
                      icon={
                        type === 'listing' ? 'HomeIcon' : 'CalendarEventIcon'
                      }
                      onClick={() => toggleType()}
                    />
                  )
                }
              >
                {currentValue?.label || (
                  <Input.Placeholder>Select {type}</Input.Placeholder>
                )}
              </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown>
              <Combobox.Header px={5}>
                <SegmentedControl
                  w="100%"
                  data={[
                    {
                      label: 'Listing',
                      value: 'listing' satisfies SelectType,
                    },
                    {
                      label: 'Reservation',
                      value: 'reservation' satisfies SelectType,
                    },
                  ]}
                  value={type}
                  radius="md"
                  onChange={(t) => {
                    console.log(t);

                    toggleType(t as SelectType);
                  }}
                  size="xs"
                />
              </Combobox.Header>
              <Combobox.Search
                value={search}
                onChange={(event) => setSearch(event.currentTarget.value)}
                placeholder="Search ..."
              />
              <Combobox.Options
                mah={340}
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                {options.length > 0 ? (
                  options
                ) : (
                  <Combobox.Empty>Nothing found</Combobox.Empty>
                )}
                <LoadingOverlay visible={loadingList} />
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
        );
      }}
    />
  );
};
