import { accountAssignmentType_enum } from '@finalytic/graphql';
import { Maybe } from '@finalytic/utils';

export const getTransactionLineType = (
  transaction_line: Maybe<{
    accountAssignmentType?: accountAssignmentType_enum | null | undefined;
  }>
) => {
  if (transaction_line?.accountAssignmentType === 'deposit_refund') {
    return 'refund' as const;
  }

  if (transaction_line?.accountAssignmentType === 'deposit_reserve') {
    return 'reserve' as const;
  }

  if (
    !transaction_line?.accountAssignmentType ||
    transaction_line.accountAssignmentType === 'deposit_resolution'
  ) {
    return 'resolution' as const;
  }

  if (transaction_line?.accountAssignmentType === 'accountsReceivable') {
    return 'payment' as const;
  }

  if (transaction_line?.accountAssignmentType === 'deposit_merchantFee') {
    return 'merchantFee' as const;
  }

  if (transaction_line?.accountAssignmentType === 'deposit_channelFee') {
    return 'channelFee' as const;
  }

  return null;
};
