import {
  useDashboard,
  useEnabledFeatures,
  useIntercom,
  useSubscription,
  useTeam,
} from '@finalytic/data';
import { Anchor, Text } from '@mantine/core';
import { ComponentProps, ReactNode, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import {
  Layout,
  OnboardingSidebar,
  OnboardingStep,
  OnboardingTeamSwitch,
} from './components';
import { OnboardingMenu } from './components/OnboardingMenu';
import { OnboardingConnectRoute } from './routes/OnboardingConnectRoute';
import { OnboardingPricingRoute } from './routes/OnboardingPricingRoute';
import { OnboardingSelectListingRoute } from './routes/OnboardingSelectListingRoute';
import { OnboardingSettingsRoute } from './routes/OnboardingSettingsRoute';

export const OnboardingRoutes = () => {
  const [
    { propertyManagementSystems, id: teamId, billingSubscriptionStatus },
    refetchTeam,
  ] = useTeam();
  const [dashboard] = useDashboard();
  const { GL } = useEnabledFeatures();
  const location = useLocation();

  const hasPms = propertyManagementSystems.length > 0;

  const { data: subscription } = useSubscription(
    (q, args) => {
      const tenant = q.tenantById({
        id: args.teamId,
      });

      return {
        billingStatus:
          tenant?.billingSubscriptionStatus as typeof billingSubscriptionStatus,
      };
    },
    {
      teamId,
    },
    {
      skip: !teamId,
    }
  );

  const billingStatus =
    subscription?.billingStatus || billingSubscriptionStatus;

  useEffect(() => {
    if (subscription?.billingStatus) {
      refetchTeam();
    }
  }, [subscription?.billingStatus]);

  if (GL)
    return (
      <Routes>
        <Route element={<OnboardingLayout />}>
          {!billingStatus || billingStatus === 'pending' ? (
            <>
              <Route
                path="/connect-pms"
                element={<OnboardingConnectRoute type="PMS" />}
              />

              <Route path="/start-date" element={<OnboardingSettingsRoute />} />
              <Route path="/trial" element={<OnboardingPricingRoute />} />
              <Route
                path="/*"
                element={<Navigate to="/connect-pms" replace />}
              />
            </>
          ) : (
            <>
              <Route
                path="/select-listing"
                element={<OnboardingSelectListingRoute />}
              />
              <Route
                path="/*"
                element={<Navigate to={`/select-listing${location.search}`} />}
              />
            </>
          )}
        </Route>
        <Route
          path="/callback"
          element={
            <Navigate
              to={`/connect-pms${location.search || ''}&callback=true`}
            />
          }
        />
      </Routes>
    );

  return (
    <Routes>
      <Route element={<OnboardingLayout />}>
        {!billingStatus || dashboard === 'partner' ? (
          <>
            <Route path="/trial" element={<OnboardingPricingRoute />} />
            <Route path="/*" element={<Navigate to="/trial" replace />} />
          </>
        ) : (
          <>
            <Route
              path="/connect-pms"
              element={<OnboardingConnectRoute type="PMS" />}
            />
            <Route
              path="/connect-accounting"
              element={<OnboardingConnectRoute type="Accounting" />}
            />

            <Route
              path="/*"
              element={
                <Navigate
                  to={
                    hasPms
                      ? `/connect-accounting${location.search}`
                      : `/connect-pms${location.search}`
                  }
                />
              }
            />
          </>
        )}
      </Route>
      <Route
        path="/callback"
        element={
          <Navigate to={`/connect-pms${location.search || ''}&callback=true`} />
        }
      />
    </Routes>
  );
};

type SidebarProps = Pick<
  ComponentProps<typeof Layout>,
  'sidebar' | 'title' | 'description'
>;

const OnboardingLayout = () => {
  const location = useLocation();
  const { show: showIntercom } = useIntercom();
  const [dashboard] = useDashboard();
  const { GL } = useEnabledFeatures();

  type OnboardingPath =
    | '/trial'
    | '/start-date'
    | '/select-listing'
    | '/connect-pms'
    | '/connect-accounting';

  const side: Record<OnboardingPath, SidebarProps> = {
    '/trial': {
      title: '14-Day Free Trial with VRPlatform',
      description:
        "Try our accounting automation and owner statement tools with no risk and no commitment. We won't charge you until after your free 14 days, and you can cancel anytime.",
      sidebar: <OnboardingSidebar type="pricing" />,
    },
    '/start-date': {
      title: 'Owner Statement Settings',
      description:
        'Select your country and the month to start generating owner statements. You can pick a past date, and billing will begin then.',
      sidebar: <OnboardingSidebar type="settings" />,
    },
    '/select-listing': {
      title: 'Select a listing for your product tour',
      description:
        'Do you want to see how your owner statement would look? Select a listing, and we will show you the process.',
      sidebar: null,
    },
    '/connect-pms': {
      title: 'Connect your PMS',
      description:
        'We will collect, clean, and organize your data so you can gain valuable insights and take action based on your findings.',
      sidebar: null,
    },
    '/connect-accounting': {
      title: 'Connect your Accounting',
      description:
        'We will collect, clean, and organize your data so you can gain valuable insights and take action based on your findings.',
      sidebar: null,
    },
  };

  const steps: Record<OnboardingPath, OnboardingStep | undefined> = {
    '/connect-accounting': 'connect-accounting',
    '/connect-pms': 'connect-pms',
    '/select-listing': 'select-listing',
    '/start-date': 'start-date',
    '/trial': 'add-billing',
  };

  const footers: Record<OnboardingPath, ReactNode> = {
    '/connect-accounting': (
      <Text c="gray" ta="center">
        Having trouble connecting your accounting software?{' '}
        <Anchor inline component="button" onClick={showIntercom}>
          Contact onboarding team
        </Anchor>
      </Text>
    ),
    '/connect-pms': (
      <Text c="gray" ta="center">
        Having trouble connecting your PMS?{' '}
        <Anchor inline component="button" onClick={showIntercom}>
          Contact onboarding team
        </Anchor>
      </Text>
    ),
    '/start-date': (
      <Text c="gray" ta="center">
        Need history data? No problem contact our{' '}
        <Anchor inline component="button" onClick={showIntercom}>
          onboarding team
        </Anchor>
      </Text>
    ),

    '/select-listing': (
      <Text c="gray" ta="center">
        Not sure what listing to select? Contact our{' '}
        <Anchor inline component="button" onClick={showIntercom}>
          onboarding team
        </Anchor>
      </Text>
    ),
    '/trial': (
      <Text c="gray" ta="center">
        Do you need a help? Don’t hesitate to{' '}
        <Anchor inline component="button" onClick={showIntercom}>
          contact our onboarding team
        </Anchor>
      </Text>
    ),
  };

  const path = location.pathname as OnboardingPath;

  return (
    <Layout
      teamSelectComponent={<OnboardingTeamSwitch />}
      footer={footers[path]}
      activeStep={steps[path]}
      menu={<OnboardingMenu />}
      GL={GL}
      teamType={dashboard === 'partner' ? 'partner' : 'propertyManager'}
      {...side[path]}
    >
      <Outlet />
    </Layout>
  );
};
