import { Maybe } from '@finalytic/utils';
import { Avatar, Box, Group, Text } from '@mantine/core';

export const ConnectionNameCell = ({
  icon,
  name,
}: { icon: Maybe<string>; name: Maybe<string> }) => {
  return (
    <Group wrap="nowrap" gap="sm">
      <Avatar
        src={icon}
        size="sm"
        styles={{
          placeholder: { visibility: 'hidden' },
        }}
        sx={(theme) => ({
          border: `1px solid ${theme.colors.gray[2]}`,
        })}
      />
      <Box>
        <Text
          size="sm"
          component="p"
          fw={500}
          m={0}
          className="connection-name"
        >
          {name}
        </Text>
      </Box>
    </Group>
  );
};
