import {
  PmDashboardChartBase,
  PmDashboardChartBaseBaseProps,
} from './PmDashboardChartBase';

export const PaymentVolumeCard = ({
  endDate,
  startDate,
  currency,
}: PmDashboardChartBaseBaseProps) => {
  return (
    <PmDashboardChartBase
      title="Payment Volume"
      href="/payments"
      total="sum"
      isCurrencyAmount
      type="paymentVolume"
      startDate={startDate}
      endDate={endDate}
      currency={currency}
    />
  );
};
