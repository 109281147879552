import { Filter } from '@finalytic/components';
import { useInfiniteQuery } from '@finalytic/data';
import { useRunDrawer } from '@finalytic/data-ui';
import { action_bool_exp, change_bool_exp } from '@finalytic/graphql';
import { InfiniteTable, MRT_SortingState } from '@finalytic/table';
import { useState } from 'react';
import { useSyncedFromToTableColumns } from '../_hooks';
import { WorkflowAction } from '../_types';
import { getWorkflowActionRow, getWorkflowChangeRow } from '../_utils';
import { getLegacyJob } from '../useAutomationHistoryDrawerSubscription';
import { SyncedTablePlaceholder } from './SyncedTablePlaceholder';

export const SyncedToTable = ({
  workflowId,
  type,
  isWorkflowPending,
}: {
  workflowId: string;
  type: 'failed' | 'success';
  isWorkflowPending: boolean;
}) => {
  const { syncIds } = useRunDrawer();
  const isSync = !!syncIds?.includes(workflowId);

  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [search, setSearch] = useState('');

  const infiniteData = useInfiniteQuery(
    (q, { workflowId, search, sorting, type }, { limit, offset }) => {
      if (!workflowId)
        return {
          aggregate: 0,
          list: [],
        };

      const overwrites = q
        .issueMessageOverwrites({
          order_by: [{ pattern: 'asc_nulls_last' }],
        })
        .map((o) => ({
          pattern: o.pattern || '',
          message: o.message || '',
        }));

      if (isSync) {
        const whereChange: change_bool_exp = {
          syncId: { _eq: workflowId },
          syncType: { _eq: 'push' },
          message: search ? { _ilike: `%${search}%` } : undefined,
          status: type === 'failed' ? { _eq: 'failed' } : undefined,
        };

        const aggregate =
          q.changeAggregate({ where: whereChange }).aggregate?.count() || 0;

        const list = q
          .changes({
            where: whereChange,
            limit,
            offset,
            order_by: [{ createdAt: 'desc' }],
          })
          .map<WorkflowAction>((change) =>
            getWorkflowChangeRow(change, overwrites, 'synced-to')
          );

        return {
          aggregate,
          list,
        };
      }

      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const where: action_bool_exp = {
        job: {
          plan: {
            workflowId: { _eq: workflowId },
          },
        },
        status: type === 'failed' ? { _eq: 'failed' } : { _neq: 'failed' },
        _or: search
          ? [
              { title: { _ilike: `%${search}%` } },
              {
                uniqueRef: {
                  _ilike: `%${search}%`,
                },
              },
            ]
          : undefined,
      };

      const aggregate = q.aggregateActions({ where }).aggregate?.count() || 0;

      const list = q
        .actions({
          where,
          limit,
          offset,
          order_by,
        })
        .map((action) => getWorkflowActionRow(action, overwrites));

      if (type === 'success') {
        return {
          list,
          aggregate,
        };
      }

      const children = q
        .jobs({
          where: {
            plan: {
              workflowId: { _eq: workflowId },
            },
            status: {
              _eq: 'failed',
            },
          },
        })
        .map((j) => getLegacyJob(j, overwrites));
      return {
        list: [...list, ...children],
        aggregate: aggregate + children.length,
      };
    },
    {
      variables: {
        search: search.trim(),
        workflowId,
        sorting,
        type,
        isSync,
      },
      queryKey: ['actions'],
      subscribe: isWorkflowPending,
    }
  );

  const columns = useSyncedFromToTableColumns();

  const resetFilter = () => {
    setSearch('');
  };

  return (
    <InfiniteTable
      columns={columns}
      table={{
        hideHeader: true,
        emptyRowsFallback:
          type === 'failed'
            ? () => <SyncedTablePlaceholder status={'completed'} />
            : 'Nothing was synced here.',
      }}
      queryData={infiniteData}
      sorting={{
        setSorting,
        sorting,
      }}
      resetFilter={resetFilter}
    >
      <Filter.Search value={search} setValue={setSearch} />
    </InfiniteTable>
  );
};
