import {
  listing_bool_exp,
  reservation_bool_exp,
  reservation_status_enum_comparison_exp,
} from '@finalytic/graphql';
import { Maybe, day, ensure, hasValue } from '@finalytic/utils';
import { z } from 'zod';
import { Dashboard } from '../types';
import { whereConnectionStatusDefault } from './whereConnectionStatusDefault';
import { whereListingIsExcluded } from './whereListingIsExcluded';

export const reservationsFilterInput = {
  connectionIds: z.array(z.string()).optional(),
  date: z.tuple([z.date().nullable(), z.date().nullable()]).optional(),
  dateType: z
    .enum(['all', 'checkIn', 'checkOut', 'bookedAt', 'cancelledAt'])
    .optional(),
  bookingChannels: z.array(z.string()).optional(),
  teamId: z.string().optional(),
  search: z.string().optional(),
  status: z.string().optional(),
  listingCollectionId: z.string().nullish(),
  listingIds: z.array(z.string()).optional(),
  listingStatus: z.enum(['active', 'inactive']).optional(),
  onlyEnabledListingByAutomationId: z.string().optional(),
};

const reservationsFilter = z.object(reservationsFilterInput);

export type ReservationsFilterParams = z.infer<typeof reservationsFilter>;

type BaseWhere = {
  partnerTeamIds: string[];
  dashboard: Dashboard;
  currentTeamId: string;
  includeLines: boolean;
  meId: string;
};

export function whereReservations({
  date,
  search: s,
  listingIds,
  currentTeamId,
  dashboard,
  partnerTeamIds,
  bookingChannels,
  connectionIds,
  dateType,
  onlyEnabledListingByAutomationId,
  teamId,
  includeLines,
  status,
  listingStatus,
  meId,
  listingCollectionId,
}: BaseWhere & ReservationsFilterParams) {
  const search = s?.trim();
  const searchQuery = search
    ? ensure<reservation_bool_exp[]>([
        {
          guestName: { _ilike: `%${search}%` },
        },
        {
          uniqueRef: { _ilike: `%${search}%` },
        },
        {
          confirmationCode: { _ilike: `%${search}%` },
        },
        {
          pmsReferenceCode: { _ilike: `%${search}%` },
        },
      ])
    : undefined;

  const dateBool = (): reservation_bool_exp[] | undefined => {
    const startDate = date?.[0]
      ? day(date[0]).startOf('day').yyyymmdd()
      : undefined;
    const endDate = date?.[1]
      ? day(date[1]).add(1, 'day').yyyymmdd()
      : undefined;

    if (!startDate) return undefined;

    const start = startDate;
    const end = endDate || day(start).add(1, 'day').yyyymmdd();

    if (dateType === 'checkIn') {
      return [
        {
          checkIn: { _gte: start, _lt: end },
        },
      ];
    }
    if (dateType === 'checkOut') {
      return [
        {
          checkOut: { _gte: start, _lt: end },
        },
      ];
    }
    if (dateType === 'bookedAt') {
      return [
        {
          bookedAt: { _gte: start, _lt: end },
        },
      ];
    }
    if (dateType === 'cancelledAt') {
      return [
        {
          status: { _eq: 'cancelled' },
          cancelledAt: { _gte: start, _lt: end },
        },
      ];
    }

    return [
      {
        checkIn: { _gte: start, _lt: end },
      },
      {
        checkOut: { _gte: start, _lt: end },
      },
      {
        checkIn: { _lt: start },
        checkOut: { _gte: end },
      },
    ];
  };

  const getStatusFilter = (
    value: Maybe<string>
  ): reservation_status_enum_comparison_exp => {
    if (value === 'booked')
      return {
        _eq: 'booked',
      };
    else if (value === 'cancelled') return { _eq: 'cancelled' };

    return {
      _is_null: false,
      _neq: 'inquiry',
    };
  };

  const dateFilter = dateBool();

  const getListingFilter = (): reservation_bool_exp | undefined => {
    if (
      !(
        dashboard === 'owner' ||
        listingIds?.length ||
        listingStatus ||
        listingCollectionId
      )
    )
      return undefined;

    const listing: listing_bool_exp | undefined =
      listingCollectionId || listingStatus || dashboard === 'owner'
        ? {
            collectionId: listingCollectionId
              ? { _eq: listingCollectionId }
              : undefined,
            status: listingStatus
              ? {
                  _in:
                    // TODO: VRP-4868 remove enabled/disabled
                    listingStatus === 'active'
                      ? ['active', 'enabled']
                      : ['inactive', 'disabled'],
                }
              : undefined,
            ownerships:
              dashboard === 'owner'
                ? {
                    newOwner: {
                      userAccesses: {
                        userId: { _eq: meId },
                      },
                    },
                  }
                : undefined,
          }
        : undefined;

    return {
      _or: [
        {
          listingConnection: {
            listingId: listingIds?.length ? { _in: listingIds } : undefined,
            listing,
          },
        },
        {
          listingId: listingIds?.length ? { _in: listingIds } : undefined,
          listing,
        },
      ],
    };
  };

  return ensure<reservation_bool_exp>({
    paymentLines: dashboard === 'owner' || includeLines ? {} : undefined,
    tenantId:
      partnerTeamIds.length > 0
        ? teamId
          ? { _eq: teamId }
          : { _in: partnerTeamIds }
        : { _eq: currentTeamId },
    status: getStatusFilter(status),
    connection: connectionIds?.length
      ? {
          id: { _in: connectionIds },
          status: whereConnectionStatusDefault,
        }
      : { status: whereConnectionStatusDefault },
    channel: bookingChannels?.length
      ? { id: { _in: bookingChannels } }
      : undefined,
    _or: dateFilter ? dateFilter : undefined,
    _and: [
      searchQuery ? { _or: searchQuery } : undefined,
      onlyEnabledListingByAutomationId
        ? {
            listingConnection: {
              listing: whereListingIsExcluded(onlyEnabledListingByAutomationId),
            },
          }
        : undefined,
      getListingFilter(),
    ].filter(hasValue),
  });
}
