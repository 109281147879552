import {
  gqlV2,
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useTeamId,
} from '@finalytic/data';
import { Maybe, toTitleCase } from '@finalytic/utils';
import {
  MappingLeftSchemaUnion,
  formatOwnerName,
  whereConnectionStatusDefault,
  whereConnections,
  whereListings,
  whereOwners,
} from '@vrplatform/ui-common';
export type OverrideRow = NonNullable<
  ReturnType<typeof useAutomationOverrideTableQuery>['data']
>['pages'][number]['list'][number];

export const useAutomationOverrideTableQuery = ({
  table,
  filter,
  exceptionFilters,
}: {
  table: MappingLeftSchemaUnion | (string & {});
  filter: {
    search: Maybe<string>;
  };
  exceptionFilters: Record<string, Record<string, any>>;
}) => {
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();
  const { id: meId } = useMe();

  return useInfiniteQuery(
    (q, { table, filter, teamId, meId, GL }, { limit, offset }) => {
      let list: any[] = [];
      let aggregate = 0;

      if (table === 'finalytic.bookingChannel') {
        const where: gqlV2.booking_channel_bool_exp = {
          reservations: {
            tenantId: { _eq: teamId },
          },
          _or: filter.search?.trim()
            ? [
                {
                  uniqueRef: { _ilike: `%${filter.search?.trim()}%` },
                },
              ]
            : undefined,
          _and: exceptionFilters['finalytic.bookingChannel']
            ? [exceptionFilters['finalytic.bookingChannel']]
            : undefined,
        };

        aggregate =
          q.bookingChannelAggregate({ where }).aggregate?.count() || 0;

        list = q
          .bookingChannels({
            where,
            limit,
            offset,
            order_by: [{ uniqueRef: 'asc_nulls_last' }],
          })
          .map((item) => {
            return {
              id: item.id,
              name: toTitleCase(item.uniqueRef),
            };
          });
      }

      if (table === 'finalytic.listingCollection') {
        const search = filter.search?.trim();

        const where: gqlV2.listing_collection_bool_exp = {
          tenantId: { _eq: teamId },
          _or: search
            ? [
                {
                  name: { _ilike: `%${search}%` },
                },
              ]
            : undefined,
          _and: exceptionFilters['finalytic.listingCollection']
            ? [exceptionFilters['finalytic.listingCollection']]
            : undefined,
        };

        aggregate =
          q.listingCollectionAggregate({ where }).aggregate?.count() || 0;

        list = q
          .listingCollections({
            where,
            limit,
            offset,
            order_by: [{ name: 'asc_nulls_last' }],
          })
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
      }

      if (table === 'finalytic.owner') {
        const where = {
          ...whereOwners({
            teamId,
            search: filter.search?.trim(),
          }),
          _and: exceptionFilters['finalytic.owner']
            ? [exceptionFilters['finalytic.owner']]
            : undefined,
        };

        aggregate = q.ownerAggregate({ where }).aggregate?.count() || 0;

        list = q
          .owners({
            where,
            limit,
            offset,
            order_by: [
              {
                name: 'asc_nulls_last',
              },
            ],
          })
          .map((item) => {
            return {
              id: item.id,
              name: formatOwnerName(item),
            };
          });
      }

      if (table === 'finalytic.connection') {
        const where = {
          ...whereConnections({
            currentTeamId: teamId,
            dashboard: 'partner',
            partnerTeamIds: [],
            search: filter.search?.trim(),
            teamId,
          }),
          _and: exceptionFilters['finalytic.connection']
            ? [exceptionFilters['finalytic.connection']]
            : undefined,
        };

        aggregate = q.connectionAggregate({ where }).aggregate?.count() || 0;

        list = q
          .connection({
            where,
            limit,
            offset,
            order_by: [
              {
                name: 'asc_nulls_last',
              },
            ],
          })
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
      }

      if (table === 'finalytic.listing') {
        const defaultListingWhere = whereListings({
          currentTeamId: teamId,
          dashboard: 'propertyManager',
          partnerTeamIds: [],
          search: filter.search?.trim(),
          meId,
          GL,
        });

        const where: gqlV2.listing_bool_exp = exceptionFilters[
          'finalytic.listing'
        ]
          ? {
              _and: [
                exceptionFilters['finalytic.listing'],
                defaultListingWhere,
              ],
            }
          : defaultListingWhere;

        aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

        list = q
          .listings({
            where,
            limit,
            offset,
            order_by: [
              {
                calculated_title: 'asc_nulls_last',
              },
            ],
          })
          .map((item) => {
            return {
              id: item.id,
              name: item.title || item.name,
            };
          });
      }

      if (table === 'finalytic.app') {
        const where: gqlV2.app_bool_exp = {
          connections: {
            tenantId: { _eq: teamId },
            status: whereConnectionStatusDefault,
          },
          id: { _neq: 'finalytic' },
          name: filter.search
            ? { _ilike: `%${filter.search}%` }
            : { _is_null: false },

          _and: exceptionFilters['finalytic.app']
            ? [exceptionFilters['finalytic.app']]
            : undefined,
        };

        aggregate = q.appAggregate({ where }).aggregate?.count() || 0;

        list = q
          .app({
            where,
            limit,
            offset,
            order_by: [
              {
                name: 'asc_nulls_last',
              },
            ],
          })
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
      }

      if (table === 'finalytic.listingConnection') {
        const where: gqlV2.listing_connection_bool_exp = {
          tenantId: { _eq: teamId },
          name: filter.search
            ? { _ilike: `%${filter.search}%` }
            : { _is_null: false },

          _and: exceptionFilters['finalytic.listingConnection']
            ? [exceptionFilters['finalytic.listingConnection']]
            : undefined,
        };

        aggregate =
          q.listingConnectionAggregate({ where }).aggregate?.count() || 0;

        list = q
          .listingConnections({
            where,
            limit,
            offset,
            order_by: [
              {
                name: 'asc_nulls_last',
              },
            ],
          })
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
      }

      if (table === 'finalytic.lineType') {
        const where: gqlV2.payment_line_classification_bool_exp = {
          lines: {
            tenantId: { _eq: teamId },
          },
          name: filter.search
            ? { _ilike: `%${filter.search}%` }
            : { _is_null: false },
          _and: exceptionFilters['finalytic.lineType']
            ? [exceptionFilters['finalytic.lineType']]
            : undefined,
        };

        aggregate =
          q.paymentLineClassificationAggregate({ where }).aggregate?.count() ||
          0;

        list = q
          .paymentLineClassifications({
            where,
            limit,
            offset,
            order_by: [
              {
                name: 'asc_nulls_last',
              },
            ],
          })
          .map((item) => {
            return {
              id: item.name,
              name: item.name,
            };
          });
      }

      if (table === 'finalytic.bankAccountDigits') {
        const where: gqlV2.payment_bool_exp = {
          status: { _neq: 'cancelled' },
          type: { _in: ['payout', 'custom'] },
          bankAccountDigits: filter.search
            ? { _ilike: `%${filter.search}%` }
            : { _is_null: false },
          tenantId: { _eq: teamId },
          _and: exceptionFilters['finalytic.bankAccountDigits']
            ? [exceptionFilters['finalytic.bankAccountDigits']]
            : undefined,
        };

        aggregate =
          q
            .paymentAggregate({ where, distinct_on: ['bankAccountDigits'] })
            .aggregate?.count() || 0;

        list = q
          .payments({
            where,
            limit,
            offset,
            distinct_on: ['bankAccountDigits'],
            order_by: [
              {
                bankAccountDigits: 'asc_nulls_last',
              },
            ],
          })
          .map((item) => {
            return {
              id: item.bankAccountDigits,
              name: item.bankAccountDigits,
            };
          });
      }

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'settings',
      variables: { teamId, table, filter, meId, GL },
      skip: !teamId || !table,
    }
  );
};
