import { type TimelineItem } from '@finalytic/components';
import { useDashboard, useQuery, useTeam } from '@finalytic/data';
import { type Maybe, hasValue, toTitleCase } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import {
  formatUserName,
  getListingName,
  queryReservationFinancials,
} from '@vrplatform/ui-common';
import { getDepositLines } from '../../views/deposits/edit/get-deposit-lines';

export function useLedgerReservationDetailDrawerQuery(id: Maybe<string>) {
  const [{ id: teamId, partnerId }] = useTeam();
  const [dashboard] = useDashboard();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .reservations({
            where: {
              id: { _eq: args.id },
            },
          })
          .map((reservation) => {
            const financials = queryReservationFinancials(reservation, {
              partnerId: args.partnerId,
              tenantId: args.teamId,
              GL: false,
            });

            const exluded = financials.filter((x) => x.isInvoice !== 'invoice');
            const included = financials.filter(
              (x) => x.isInvoice === 'invoice'
            );

            const recurringFeeJournalEntries = reservation
              .journalEntries({
                where: {
                  recurringFee: {
                    tenantId: { _eq: args.teamId },
                  },
                  party: { _eq: 'manager' },
                },
                order_by: [
                  {
                    createdAt: 'asc_nulls_last',
                  },
                ],
              })
              .map((journalEntry) => {
                return {
                  id: journalEntry.id,
                  recurringFeeId: journalEntry.recurringFeeId!,
                  recurringFeeTitle:
                    journalEntry.recurringFee?.title || 'No name',
                  centTotal: journalEntry.centTotal ?? 0,
                  currency: journalEntry.currency || 'usd',
                  party: journalEntry.party,
                };
              });

            type RecurringFee = {
              id: string;
              title: string;
              centTotal: number;
              currency: string;
            };

            const recurringFees = recurringFeeJournalEntries.reduce<
              RecurringFee[]
            >((acc, journalEntry) => {
              const index = acc.findIndex(
                (x) => x.id === journalEntry.recurringFeeId
              );

              const journalEntryCentTotal = Math.abs(journalEntry.centTotal);

              if (index === -1)
                acc.push({
                  centTotal: journalEntryCentTotal,
                  currency: journalEntry.currency,
                  id: journalEntry.recurringFeeId,
                  title: journalEntry.recurringFeeTitle,
                });
              else acc[index].centTotal += journalEntryCentTotal;
              return acc;
            }, []);

            const timeline =
              args.dashboard === 'owner'
                ? []
                : reservation
                    .changeSourceLinks({
                      order_by: [{ created_at: 'desc' }],
                    })
                    .map<TimelineItem>((sourceChange) => {
                      const getIcon = () => {
                        const automation = sourceChange?.change.automation;

                        const left = {
                          id: automation?.leftConnection?.appId,
                          icon: automation?.leftConnection?.app?.iconRound,
                        };
                        const right = {
                          id: automation?.rightConnection?.appId,
                          icon: automation?.rightConnection?.app?.iconRound,
                        };

                        const apps = [left, right].filter(
                          (x) => x?.id !== 'finalytic'
                        );

                        return apps[0]?.icon || null;
                      };

                      return {
                        id: sourceChange.change.id,
                        label: [
                          toTitleCase(
                            sourceChange.change.entityUniqueRef
                              ?.split('/')
                              .at(0)
                          ),
                          sourceChange.change.message,
                        ]
                          .filter(hasValue)
                          .join(' - '),
                        date: sourceChange.created_at,
                        icon: getIcon(),
                      };
                    });

            const reservationlistingConnectionListing = {
              id: reservation.listingConnection?.listing?.id,
              name: getListingName(reservation.listingConnection?.listing),
            };

            const reservationListing = {
              id: reservation.listingId,
              name: getListingName(reservation.listing),
            };

            const depositLines = getDepositLines(
              reservation.transactionLines,
              {
                transaction: {
                  type: { _eq: 'deposit' },
                },
              },
              [
                {
                  transaction: { date: 'desc_nulls_last' },
                },
                {
                  createdAt: 'desc_nulls_last',
                },
              ]
            );

            const deposits = reservation
              .transactionLines({
                distinct_on: ['transactionId'],
                where: {
                  transaction: {
                    type: { _eq: 'deposit' },
                  },
                },
              })
              .map((line) => {
                const deposit = line.transaction;

                const connection = {
                  id: deposit?.connection?.id,
                  name: deposit?.connection?.name,
                  app: {
                    id: deposit?.connection?.app?.id,
                    iconRound: deposit?.connection?.app?.iconRound,
                    name: deposit?.connection?.app?.name,
                  },
                };

                return {
                  id: deposit?.id,
                  centTotal: deposit?.centTotal ?? 0,
                  currency: deposit?.currency ?? 'usd',
                  date: deposit?.date,
                  connection: connection?.id ? connection : null,
                };
              });

            const payments = depositLines.lines.reservationLines.filter(
              (x) => x.type === 'payment'
            );
            const reserves = depositLines.lines.reservationLines.filter(
              (x) =>
                x.type === 'resolution' &&
                x.accountAssignmentType === 'deposit_reserve'
            );

            const refunds = depositLines.lines.reservationLines.filter(
              (x) =>
                x.type === 'resolution' &&
                x.accountAssignmentType === 'deposit_refund'
            );

            const resolutions = depositLines.lines.reservationLines.filter(
              (x) =>
                x.type === 'resolution' &&
                x.accountAssignmentType === 'deposit_resolution'
            );

            return {
              id: reservation.id as string,
              bookedAt: reservation.bookedAt,
              connection: {
                id: reservation.connection?.id,
                app: {
                  id: reservation.connection?.app?.id,
                  iconRound: reservation.connection?.app?.iconRound,
                  name: reservation.connection?.app?.name,
                },
              },
              channel:
                reservation.channel?.uniqueRef || reservation.bookingPlatform,
              cancelledAt: reservation.cancelledAt,
              checkIn: reservation.checkIn,
              checkOut: reservation.checkOut,
              guestName: reservation.guestName,
              status: reservation.status,
              centTotal: reservation.centTotal,
              centPaid: reservation.centPaid ?? 0,
              paidStatus: reservation.paidStatus,
              userData: reservation.userdata(),
              confirmationCode: reservation.confirmationCode,
              pmsReferenceCode: reservation.pmsReferenceCode,
              listing: reservationListing?.id
                ? reservationListing
                : reservationlistingConnectionListing,
              currency: reservation.currency || 'usd',
              financials: {
                included,
                exluded,
              },
              deposits,
              payments,
              resolutions,
              refunds,
              reserves,
              timeline,
              recurringFees,
              files: reservation
                ?.files({
                  order_by: [{ startDate: 'desc_nulls_last' }],
                })
                .map((file) => ({
                  id: file.id,
                  type: file.type,
                  filename: file.filename,
                  ownerName: file.owner && formatUserName(file.owner),
                  startDate: file.startDate,
                  endDate: file.endDate,
                })),
              // hasListingOwnership: !!reservation.listing
              //   ?.ownershipPeriods({
              //     limit: 1,
              //   })
              //   .map((x) => x.id)[0],
              // paymentLines: paymentLines.filter((x) => x.isResevationPayment),
              // resolutionLines: paymentLines.filter(
              //   (x) => !x.isResevationPayment
              // ),
            };
          })[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'reservations',
      variables: {
        id,
        teamId,
        partnerId,
        dashboard,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

export type Reservation = NonNullable<
  ReturnType<typeof useLedgerReservationDetailDrawerQuery>['data']
>;
